/**
 * @require ./_base.js
 */
(function (global) {
    /**
     * @param options
     * @augments {PopupBase}
     * @constructor
     */
    var SelectIssueStatesForCommit = function (options) {
        if (!options.ID) {
            options.ID = 'select-issue-states-for-commit';
        }

        options.ResizeToFitToContent = true;
        options.ShowOverlay = true;

        if (!options.Buttons) {
            options.Buttons = {
                Close: {
                    Caption: i18next.t('misc.close')
                }
            };
        }

        options.Styles = {
            '_': {
                'z-index': 14001,
                width: 650
            },
            '.content': {
                overflow: 'auto',
                padding: '10px'
            },
            '.content > p:first-child': {
                margin: '0 0 1rem 0'
            },
            '.status-wrapper': {
                display: 'flex',
                gap: '1rem',
                'align-items': 'center'
            },
            '.status-wrapper > label': {
                width: '7rem',
                'text-overflow': 'ellipsis',
                overflow: 'hidden'
            },
            '.selection-wrapper + .selection-wrapper': {
                'margin-top': '.5rem'
            }
        };

        var defaultModifiedOpenStatus = changemode.Properties[Client.Settings.TicketOpened];
        var defaultModifiedClosedStatus = changemode.Properties[Client.Settings.TicketCompleted];

        if (defaultModifiedOpenStatus.ModificationType === Enums.ModificationType.NotModified ||
            defaultModifiedOpenStatus.ModificationType === Enums.ModificationType.Edited) {
            this.SelectedOpenStatusOID = defaultModifiedOpenStatus.OID;
        }

        if (defaultModifiedClosedStatus.ModificationType === Enums.ModificationType.NotModified ||
            defaultModifiedClosedStatus.ModificationType === Enums.ModificationType.Edited) {
            this.SelectedClosedStatusOID = defaultModifiedClosedStatus.OID;
        }

        global.PopupBase.call(this, options);
    };

    SelectIssueStatesForCommit.prototype = Object.create(global.PopupBase.prototype, {
        constructor: SelectIssueStatesForCommit
    });

    SelectIssueStatesForCommit.prototype.CreateContentMarkup = function () {
        return [
            '<p class="message-text">',
                i18next.t('changeMode.messages.commitModificationsDeleteStatusInUse.text'),
            '</p>',
            '<div class="selection-wrapper">',
                '<div class="status-wrapper">',
                    '<label>', i18next.t('changeMode.messages.commitModificationsDeleteStatusInUse.openStatus'), ':</label>',
                    '<span class="btn-steal btn-select-open-status">', createButtonCaption(Properties[this.SelectedOpenStatusOID]), '</span>',
                '</div>',
            '</div>',
            '<div class="selection-wrapper">',
                '<div class="status-wrapper">',
                    '<label>', i18next.t('changeMode.messages.commitModificationsDeleteStatusInUse.closedStatus'), ':</label>',
                    '<span class="btn-steal btn-select-closed-status">', createButtonCaption(Properties[this.SelectedClosedStatusOID]), '</span>',
                '</div>',
            '</div>'
        ].join('');
    };

    SelectIssueStatesForCommit.prototype.BindAdditionalEvents = function () {
        this.$content.find('.btn-select-open-status').on('click', $.proxy(onSelectOpenStatus, this));
        this.$content.find('.btn-select-closed-status').on('click', $.proxy(onSelectClosedStatus, this));
    };

    SelectIssueStatesForCommit.prototype.OnAfterRendered = function () {
        if (!this.SelectedOpenStatusOID || !this.SelectedClosedStatusOID) {
            this.$bottom.find('.btn-yes').attr('disabled', 'disabled');
        }
    };

    function createButtonCaption(status) {
        if (!status) {
            return i18next.t('changeMode.messages.commitModificationsDeleteStatusInUse.pleaseSelectStatus');
        }

        return [
            '<span class="color" style="background-color:', status.Color, '";"></span>',
            Tools.escapeHtml(status.Title)
        ].join('');
    }

    function onSelectOpenStatus(evt) {
        var $btn = $(evt.currentTarget);
        var me = this;

        showStatusSelection(me.SelectedOpenStatusOID, false, function (status) {
            me.SelectedOpenStatusOID = status.OID;
            $btn.html(createButtonCaption(status));

            me.$bottom.find('.btn-yes').removeAttr('disabled');
        });
    }

    function onSelectClosedStatus(evt) {
        var $btn = $(evt.currentTarget);
        var me = this;

        showStatusSelection(me.SelectedClosedStatusOID, true, function (status) {
            me.SelectedClosedStatusOID = status.OID;
            $btn.html(createButtonCaption(status));

            me.$bottom.find('.btn-yes').removeAttr('disabled');
        });
    }

    function showStatusSelection(selectedStatusOID, showClosedStatus, callback) {
        Tools.WindowWrappers.ShowStatusSelection({
            selectedStatusses: [selectedStatusOID],
            selectMultiple: false,
            windowCaption: i18next.t('issueViewer.panels.settings.selectStatus'),
            showCheckbox: false,
            onApplySelection: function (statusOID) { callback(Properties[statusOID]); },
            statusState: showClosedStatus ? Enums.StatusState.Closed : Enums.StatusState.Open,
            additionalFilter: function (status) {
                if (status.IsDeletedState) {
                    return false;
                }

                var modifiedStatus = changemode.Properties[status.OID];

                if (!modifiedStatus) {
                    return false;
                }

                return modifiedStatus.ModificationType === Enums.ModificationType.NotModified ||
                    modifiedStatus.ModificationType === Enums.ModificationType.Edited;
            }
        });
    }

    global.SelectIssueStatesForCommit = SelectIssueStatesForCommit;
})(Modifications.Popups || (Modifications.Popups = {}));