/**
 * @require ./pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @abstract
     * @augments {BaseComponent}
     * @typedef {CheckpointBaseComponent}
     */
    const CheckpointBaseComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {string} icon
         * @param {ComponentSettings} settings
         * @constructor
         */
        function CheckpointBaseComponent(checkpoint, icon, settings) {
            if (this.constructor === CheckpointBaseComponent) {
                throw new Error('This class cannot be instanciated.')
            }

            this.ComponentSettings = this.ComponentSettings || [];

            let contentSettings = Tools.getFirst(this.ComponentSettings, 'ContentSettings', 'Key');

            if (!contentSettings) {
                contentSettings = {
                    Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.label'),
                    Key: 'ContentSettings',
                    Settings: []
                };

                this.ComponentSettings.unshift(contentSettings);
            }

            contentSettings.Settings.unshift({
                Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.all.displayProperty.label'),
                Key: 'ContentType',
                Type: global.Model.Enums.ComponentRepresentation.Dropdown,
                SubType: 'number',
                Options: [
                    {
                        Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.all.displayProperty.value'),
                        Value: global.Model.Enums.CheckpointContentType.Value
                    },
                    {
                        Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.all.displayProperty.title'),
                        Value: global.Model.Enums.CheckpointContentType.Title
                    },
                    {
                        Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.all.displayProperty.description'),
                        Value: global.Model.Enums.CheckpointContentType.Description
                    },
                    {
                        Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.all.displayProperty.creatorName'),
                        Value: global.Model.Enums.CheckpointContentType.CreatorName
                    },
                    {
                        Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.all.displayProperty.editorName'),
                        Value: global.Model.Enums.CheckpointContentType.EditorName
                    },
                    {
                        Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.all.displayProperty.creationTimestamp'),
                        Value: global.Model.Enums.CheckpointContentType.CreationTimestamp
                    },
                    {
                        Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.all.displayProperty.modificationTimestamp'),
                        Value: global.Model.Enums.CheckpointContentType.ModificationTimestamp
                    }
                ]
            });

            this.ComponentSettings = (this.ComponentSettings || []).concat([
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.layout.label'),
                    Key: 'Label',
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.label'),
                            Key: 'Layout.HorizontalAlignment',
                            Type: global.Model.Enums.ComponentRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.left'),
                                    Icon: './img/align-left-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Left
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.center'),
                                    Icon: './img/align-center-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.right'),
                                    Icon: './img/align-right-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Right
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.label'),
                            Key: 'Layout.VerticalAlignment',
                            Type: global.Model.Enums.ComponentRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.top'),
                                    Icon: './img/vertical-align-top.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Top
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.center'),
                                    Icon: './img/vertical-align-center.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.bottom'),
                                    Icon: './img/vertical-align-bottom.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Bottom
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.rotation.label'),
                            Key: 'Layout.Rotation',
                            Type: global.Model.Enums.ComponentRepresentation.Input,
                            SubType: 'number',
                            Min: -360,
                            Max: 360,
                            Default: 0
                        }
                    ]
                }
            ]);

            this.ParameterOID = checkpoint.OID;
            this.Checkpoint = checkpoint;

            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Checkpoint,
                checkpoint.Title,
                checkpoint.Description,
                icon,
                settings
            );

            this.CommonSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.information.label'),
                    CollapsedByDefault: true,
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.information.checkpointGroup'),
                            Key: 'Checkpoint.Parent.Title',
                            Type: global.Model.Enums.ComponentRepresentation.Info
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.information.checkpoint'),
                            Key: 'Checkpoint.Title',
                            Type: global.Model.Enums.ComponentRepresentation.Info
                        }
                    ]
                }
            ].concat(this.CommonSettings);
        }

        CheckpointBaseComponent.prototype = Object.create(global.Model.BaseComponent.prototype);

        CheckpointBaseComponent.prototype.CreateSelectionMarkup = function () {
            const markup = [
                `<li class="component-prototype" id="${this.OID}" data-identifier="${this.OID}" data-type="${this.Type}" data-checkpoint-oid="${this.Checkpoint.OID}">`
            ];

            if (!!this.Icon) {
                const typeCaption = Tools.element.getTypeName(this.Checkpoint);

                markup.push(`<div class="icon-wrapper" title="${typeCaption}"><img src="./img/${this.Icon}" /></div>`);
            }

            markup.push(`<div class="text-wrapper"><strong>${this.Title}</strong>`);

            if (!!this.Description) {
                markup.push(`<p class="description">${this.Description}</p>`);
            }

            markup.push('</div></li>');

            return markup.join('');
        };

        CheckpointBaseComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY, additionalSettings) {
            let settings = this.Settings || {};

            settings.OID = uuid();
            delete settings.IsTemplate;

            let component = new this.constructor(this.Checkpoint, settings);

            if (additionalSettings) {
                component = Object.assign(component, additionalSettings);
            }

            component.Width = component.DefaultDimensions.Width;
            component.Height = component.DefaultDimensions.Height;

            component.Textflow = { Text: `{{${global.Model.Enums.CheckpointTextPrefixes.Value}${this.Checkpoint.OID}}}` };
            component.Position = {
                Type: global.Model.Enums.PositionType.Absolute,
                Coordinates: {
                    X: relativeX,
                    Y: relativeY
                }
            };

            component.Layout = {
                HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                VerticalAlignment: global.Model.Enums.VerticalAlignment.Top
            };

            component.ContentType = global.Model.Enums.CheckpointContentType.Value;

            delete component.Title;
            delete component.Description;
            delete component.Icon;

            return $.Deferred().resolve(component).promise();
        };

        /**
         * @return {string}
         */
        CheckpointBaseComponent.prototype.CreateComponentContent = function () {
            return `<span>{{${Tools.escapeHtml($.trim(this.Checkpoint.Title))}}}</span>`;
        };

        CheckpointBaseComponent.prototype.constructor = CheckpointBaseComponent;

        return CheckpointBaseComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {CheckboxCpComponent}
     */
    global.Model.CheckboxCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function CheckboxCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'square-check-regular.svg', settings);
        }

        CheckboxCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        CheckboxCpComponent.prototype.constructor = CheckboxCpComponent;

        return CheckboxCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {NumberCpComponent}
     */
    global.Model.NumberCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function NumberCpComponent(checkpoint, settings) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.label'),
                    Key: 'ContentSettings',
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.number.hideUnit.label'),
                            Key: 'SuppressUnit',
                            Type: global.Model.Enums.ComponentRepresentation.Boolean,
                            DefaultValue: false
                        }
                    ]
                }
            ];

            CheckpointBaseComponent.call(this, checkpoint, '1-solid.svg', settings);
        }

        NumberCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        NumberCpComponent.prototype.constructor = NumberCpComponent;

        return NumberCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {LineCpComponent}
     */
    global.Model.LineCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function LineCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'font-solid.svg', settings);
        }

        LineCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        LineCpComponent.prototype.constructor = LineCpComponent;

        return LineCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {MemoCpComponent}
     */
    global.Model.MemoCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function MemoCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'font-solid.svg', settings);
        }

        MemoCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        MemoCpComponent.prototype.constructor = MemoCpComponent;

        return MemoCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {DateCpComponent}
     */
    global.Model.DateCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function DateCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'calendar-days-solid.svg', settings);
        }

        DateCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        DateCpComponent.prototype.constructor = DateCpComponent;

        return DateCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {TimeCpComponent}
     */
    global.Model.TimeCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function TimeCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'clock-regular.svg', settings);
        }

        TimeCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        TimeCpComponent.prototype.constructor = TimeCpComponent;

        return TimeCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {PhotoCpComponent}
     */
    global.Model.PhotoCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function PhotoCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'image-regular.svg', settings);
        }

        PhotoCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        PhotoCpComponent.prototype.constructor = PhotoCpComponent;

        return PhotoCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {ScanCodeCpComponent}
     */
    global.Model.ScanCodeCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function ScanCodeCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'barcode-solid.svg', settings);
        }

        ScanCodeCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        ScanCodeCpComponent.prototype.constructor = ScanCodeCpComponent;

        return ScanCodeCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {LocationCodeCpComponent}
     */
    global.Model.LocationCodeCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function LocationCodeCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'location-dot-solid.svg', settings);
        }

        LocationCodeCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        LocationCodeCpComponent.prototype.constructor = LocationCodeCpComponent;

        return LocationCodeCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {ListBoxCpComponent}
     */
    global.Model.ListBoxCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function ListBoxCpComponent(checkpoint, settings) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.label'),
                    Key: 'ContentSettings',
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.listBox.showAllOptions.label'),
                            Key: 'ShowAllListboxOptions',
                            Type: global.Model.Enums.ComponentRepresentation.Boolean,
                            DefaultValue: false
                        }
                    ]
                }
            ];

            CheckpointBaseComponent.call(this, checkpoint, 'list-solid.svg', settings);
        }

        ListBoxCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        ListBoxCpComponent.prototype.constructor = ListBoxCpComponent;

        return ListBoxCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {MultiListBoxCpComponent}
     */
    global.Model.MultiListBoxCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function MultiListBoxCpComponent(checkpoint, settings) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.label'),
                    Key: 'ContentSettings',
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.checkpointComponentContent.listBox.showAllOptions.label'),
                            Key: 'ShowAllListboxOptions',
                            Type: global.Model.Enums.ComponentRepresentation.Boolean,
                            DefaultValue: false
                        }
                    ]
                }
            ];

            CheckpointBaseComponent.call(this, checkpoint, 'list-check-solid.svg', settings);
        }

        MultiListBoxCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        MultiListBoxCpComponent.prototype.constructor = MultiListBoxCpComponent;

        return MultiListBoxCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {InfoCpComponent}
     */
    global.Model.InfoCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function InfoCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'info-solid.svg', settings);
        }

        InfoCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        InfoCpComponent.prototype.constructor = InfoCpComponent;

        return InfoCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {SignatureCpComponent}
     */
    global.Model.SignatureCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function SignatureCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'signature-solid.svg', settings);
        }

        SignatureCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        SignatureCpComponent.prototype.constructor = SignatureCpComponent;

        return SignatureCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {UserAndTeamsCpComponent}
     */
    global.Model.UserAndTeamsCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function UserAndTeamsCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'users-solid.svg', settings);
        }

        UserAndTeamsCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        UserAndTeamsCpComponent.prototype.constructor = UserAndTeamsCpComponent;

        return UserAndTeamsCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {IndividualDataCpComponent}
     */
    global.Model.IndividualDataCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function IndividualDataCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'star-solid.svg', settings);
        }

        IndividualDataCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        IndividualDataCpComponent.prototype.constructor = IndividualDataCpComponent;

        return IndividualDataCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {PhoneNumberCpComponent}
     */
    global.Model.PhoneNumberCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function PhoneNumberCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'phone-solid.svg', settings);
        }

        PhoneNumberCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        PhoneNumberCpComponent.prototype.constructor = PhoneNumberCpComponent;

        return PhoneNumberCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {EMailAddressesCpComponent}
     */
    global.Model.EMailAddressesCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function EMailAddressesCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'envelope-regular.svg', settings);
        }

        EMailAddressesCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        EMailAddressesCpComponent.prototype.constructor = EMailAddressesCpComponent;

        return EMailAddressesCpComponent;
    })();

    /**
     * @augments {CheckpointBaseComponent}
     * @typedef {FilesCpComponent}
     */
    global.Model.FilesCpComponent = (function () {
        /**
         * @param {object} checkpoint
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function FilesCpComponent(checkpoint, settings) {
            CheckpointBaseComponent.call(this, checkpoint, 'paperclip-solid.svg', settings);
        }

        FilesCpComponent.prototype = Object.create(CheckpointBaseComponent.prototype);
        FilesCpComponent.prototype.constructor = FilesCpComponent;

        return FilesCpComponent;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));