/**
 * @require ../_base.js
 */
(function (global) {
    /**
     * @typedef Point
     * @property {int} X
     * @property {int} Y
     */

    /**
     * Erstellt eine neue Instanz des PDF-Designers
     * @param options
     * @augments {PopupBase}
     * @constructor
     */
    const PdfDesigner = function (options) {
        if (!options.ID) {
            options.ID = 'pdf-designer';
        }

        options.Title = i18next.t('changeMode.pdfDesigner.window.title', { formTitle: options.Form.Title });
        options.ShowOverlay = true;

        if (!options.Buttons) {
            options.Buttons = {
                Close: {
                    Caption: i18next.t('misc.close')
                }
            };
        }

        options.Styles = {
            '_': {
                'z-index': 14001,
                width: 1450,
                height: '90%'
            },
            '.content': {
                padding: '10px'
            },
            '.content > p:first-child': {
                margin: '0 0 1rem 0'
            },
            '.component-list-switch': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '20rem',
                height: '3rem',
                'border-right': '1px solid #ccc',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                padding: '.5rem 1rem'
            },
            '.component-list-switch .switch': {
                padding: '.5rem',
                'border': '1px solid #ddd',
                width: '5rem',
                'text-align': 'center'
            },
            '.component-list-switch .switch:not(:first-child)': {
                'border-left': 'none'
            },
            '.component-list-switch .switch:first-child': {
                'border-top-left-radius': '.5rem',
                'border-bottom-left-radius': '.5rem'
            },
            '.component-list-switch .switch:last-child': {
                'border-top-right-radius': '.5rem',
                'border-bottom-right-radius': '.5rem'
            },
            '.components': {
                position: 'absolute',
                top: '4rem',
                bottom: 0,
                left: 0,
                width: '22rem',
                'border-right': '1px solid #ccc'
            },
            '.components .search': {
                padding: '.4rem .5rem .4rem .5rem',
                'border-bottom': '1px dashed #ccc'
            },
            '.components .search input': {
                width: 'calc(100% - 2.5rem)',
                'padding-left': '1.75rem',
                'background-image': 'url(./img/magnifier_gray.svg)',
                'background-position': '5px center',
                'background-repeat': 'no-repeat',
                'background-size': '14px 14px'
            },
            '.component-tab': {
                position: 'absolute',
                top: '3.5rem',
                bottom: 0,
                left: 0,
                right: 0,
                overflow: 'auto'
            },
            '.component-list li': {
                padding: '1rem',
                display: 'flex',
                gap: '1rem'
            },
            '.component-list li .icon-wrapper': {
                width: '2.5rem',
                height: '2.5rem',
                'background-color': '#f1f1f1',
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                'border-radius': '.5rem',
                flex: '0 0 2.5rem'
            },
            '.component-list li .icon-wrapper img': {
                width: '1rem',
                'max-height': '1rem'
            },
            '.component-list li .text-wrapper': {
                'max-width': '15rem',
                overflow: 'hidden',
                'text-overflow': 'ellipsis'
            },
            '.component-list li[data-type="signature"] .icon-wrapper img': {
                width: '2rem',
            },
            '.component-list li .description': {
                margin: 0,
                color: '#666'
            },
            '.section-navigation': {
                position: 'absolute',
                top: 0,
                right: 0,
                left: '22rem',
                height: '4rem',
                'background-color': '#eee',
                display: 'flex',
                'align-items': 'flex-end',
                padding: '0 1rem',
                'border-bottom': '1px solid #ccc',
                'border-left': '1px solid #ccc'
            },
            '.canvas-toolbar .button-wrapper': {
                display: 'flex',
                'align-items': 'center',
            },
            '.section-wrapper': {
                position: 'absolute',
                top: 'calc(3rem + 15px)',
                right: '20rem',
                bottom: 0,
                left: '22rem',
                overflow: 'auto',
                'background-color': '#f1f1f1',
                'border-left': '1px solid #ccc'
            },
            '.section-wrapper .section-toolbar': {
                display: 'flex',
                'align-items': 'center',
                height: '3rem',
                'border-bottom': '1px solid #ddd',
                padding: '0 1rem',
                'background-color': '#fff'
            },
            '.section-wrapper .section-toolbar .add-page .icon': {
                display: 'flex',
                'justify-content': 'center',
                padding: 'none'
            },
            '.section-wrapper .section-toolbar .add-page img': {
                width: '1.2rem'
            },
            '.section-wrapper .canvas-wrapper': {
                position: 'absolute',
                top: '3rem',
                right: 0,
                bottom: 0,
                left: 0,
                padding: '2rem 0'
            },
            '.component-settings': {
                position: 'absolute',
                right: 0,
                bottom: 0,
                top: 'calc(15px + 3rem)',
                width: '18rem',
                'border-left': '1px solid #ddd',
                overflow: 'auto',
                padding: '1rem'
            }
        };

        global.PopupBase.call(this, options);

        initPredefinedComponents.call(this);
        initCheckpointComponents.call(this);

        /**
         * Cache der Gesamtdaten der aktuellen Editor-Instanz
         * @type {Section[]}
         */
        this.Sections = prepareRawSections.call(this, options.Sections) || createDefaultSection.call(this);

        /**
         * Gibt an, welche Kategorie an Komponenten in der Seitenleiste aktuell angezeigt wird
         * @type {string}
         */
        this.CurrentComponentTab = 'predefined';

        /**
         * Cache des aktuell ausgewählten Bereichs
         * @type {Section}
         */
        this.CurrentSection = this.Sections[0];

        /**
         * Cache der aktuell ausgewählten Komponente
         * @type {BaseComponent|null}
         */
        this.ActiveComponent = null;

        /**
         * Stellt einen Cache für die Interact-Events des Fensters zur Verfügung
         */
        this.Interacts = {
            prototypes: {
                draggable: null,
                droppable: null
            },
            pages: {
                draggable: null
            },
            header: {
                resizable: null
            },
            footer: {
                resizable: null
            }
        };

        /**
         * Gibt an, welcher Seitenbereich aktuell bearbeitet werden soll
         * @type {string}
         */
        this.SectionModificationArea = global.PdfDesigner.Model.Enums.SectionModificationArea.Page;
    };

    /**
     * Bereitet rohe Designer-Daten für die Verwendung innerhalb des Editors vor
     * @param {*[]} sections
     * @return {Section[]|null}
     */
    function prepareRawSections(sections) {
        if (!(sections instanceof Array) || !sections.length) {
            return null;
        }

        const pageSettings = getDefaultPageSettings.call(this);
        const marginalSettings = getDefaultMarginalSettings.call(this);
        const sectionSettings = getDefaultSectionSettings();
        const componentFactory = new global.PdfDesigner.Model.ComponentFactory(
            this.CheckpointMap
        );

        /**
         * @type {Section[]}
         */
        const preparedSections = [];

        for (let sCnt = 0; sCnt < sections.length; sCnt++){
            const section = sections[sCnt];
            const header = new global.PdfDesigner.Model.Marginal(
                marginalSettings,
                section.Header ? section.Header.Height : 10,
                global.PdfDesigner.Model.Enums.SectionModificationArea.Header,
                section.Header ? section.Header.Content : null,
                componentFactory
            );

            const footer = new global.PdfDesigner.Model.Marginal(
                marginalSettings,
                section.Footer ? section.Footer.Height : 10,
                global.PdfDesigner.Model.Enums.SectionModificationArea.Footer,
                section.Footer ? section.Footer.Content : null,
                componentFactory
            );

            preparedSections.push(
                new global.PdfDesigner.Model.Section(
                    sectionSettings,
                    pageSettings,
                    header,
                    footer,
                    section.Segments,
                    componentFactory
                )
            );
        }

        return preparedSections;
    }

    /**
     * Stellt Standardeinstellungen für Bereiche zur Verfügung
     * @return {SectionSettings}
     */
    function getDefaultSectionSettings() {
        return new global.PdfDesigner.Model.SectionSettings(
            global.PdfDesigner.Model.PageFormat.A4,
            global.PdfDesigner.Model.PageOrientation.Portrait
        );
    }

    /**
     * Stell Standardeinstellungen für Kopf-/Fußzeilen zur Verfügung
     * @return {MarginalSettings}
     */
    function getDefaultMarginalSettings() {
        return new global.PdfDesigner.Model.MarginalSettings(
            '#' + this.options.ID,
            '.component-settings',
            $.proxy(onPageClick, this),
            $.proxy(onPageComponentClick, this),
            $.proxy(onMarginalComponentEdit, this),
            $.proxy(onPageComponentRemove, this)
        );
    }

    /**
     * Stellt Standardeinstellungen für Seiten zur Verfügung
     * @return {PageSettings}
     */
    function getDefaultPageSettings() {
        return new global.PdfDesigner.Model.PageSettings(
            '#' + this.options.ID,
            '.component-settings',
            $.proxy(onPageClick, this),
            $.proxy(onPageComponentClick, this),
            $.proxy(onPageComponentRemove, this)
        );
    }

    /**
     * Erstellt einen Standardbereich
     * @return {Section}
     */
    function createDefaultSection() {
        const sectionSettings = getDefaultSectionSettings();
        const pageSettings = getDefaultPageSettings.call(this);
        const marginalSettings = getDefaultMarginalSettings.call(this);
        const page = new global.PdfDesigner.Model.Page(pageSettings, 1);

        return [
            new global.PdfDesigner.Model.Section(
                sectionSettings,
                pageSettings,
                new global.PdfDesigner.Model.Marginal(
                    marginalSettings,
                    10,
                    global.PdfDesigner.Model.Enums.SectionModificationArea.Header
                ),
                new global.PdfDesigner.Model.Marginal(
                    marginalSettings,
                    10,
                    global.PdfDesigner.Model.Enums.SectionModificationArea.Footer
                ),
                [page]
            )
        ];
    }

    /**
     * Initialisiert die vom System vorgegebenen Komponenten
     */
    function initPredefinedComponents() {
        /**
         * @type {BaseComponent[]}
         */
        const staticComponents = [
            new global.PdfDesigner.Model.TextComponent(
                i18next.t('changeMode.pdfDesigner.components.static.textField.label'),
                i18next.t('changeMode.pdfDesigner.components.static.textField.description'),
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true})
            ),
            new global.PdfDesigner.Model.CataloguePictureComponent(
                i18next.t('changeMode.pdfDesigner.components.static.cataloguePicture.label'),
                i18next.t('changeMode.pdfDesigner.components.static.cataloguePicture.description'),
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true})
            ),
            new global.PdfDesigner.Model.TableComponent(
                i18next.t('changeMode.pdfDesigner.components.static.table.label'),
                i18next.t('changeMode.pdfDesigner.components.static.table.description'),
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true})
            ),
            new global.PdfDesigner.Model.RectangleComponent(
                i18next.t('changeMode.pdfDesigner.components.static.rectangle.label'),
                i18next.t('changeMode.pdfDesigner.components.static.rectangle.description'),
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true})
            )
        ];

        /**
         * @type {BaseComponent[]}
         */
        const documentInformation = [
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.documentInformation.pageNumber.label'),
                i18next.t('changeMode.pdfDesigner.components.documentInformation.pageNumber.description'),
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{Page}}'}),
                '1-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.documentInformation.pageCount.label'),
                i18next.t('changeMode.pdfDesigner.components.documentInformation.pageCount.description'),
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{MaxPage}}'}),
                '1-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.documentInformation.creationDate'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{CreationDate}}'}),
                'calendar-days-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.documentInformation.creationTime'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{CreationTime}}'}),
                'clock-regular.svg'
            )
        ];

        /**
         * @type {BaseComponent[]}
         */
        const issueInformationComponents = [
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.id'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueId}}'}),
                '1-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.revision'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueRevision}}'}),
                '1-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.type'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueType}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.title'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueTitle}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.description'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueDescription}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.creatorName'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueCreatorName}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.creationTimestamp'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueCreationTimestamp}}'}),
                'calendar-days-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.editorName'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueEditorName}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.modificationTimestamp'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueModificationTimestamp}}'}),
                'calendar-days-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.priority'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssuePriorityTitle}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.state'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueStateTitle}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.deadlineTimestamp'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueDeadlineTimestamp}}'}),
                'calendar-days-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.ouTitle'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueLocationTitle}}'}),
                'font-solid.svg'
            ),
            new global.PdfDesigner.Model.GenericInformationComponent(
                i18next.t('changeMode.pdfDesigner.components.issueInformation.formTitle'),
                null,
                new global.PdfDesigner.Model.ComponentSettings({IsTemplate: true, Placeholder: '{{IssueFormTitle}}'}),
                'font-solid.svg'
            )
        ];

        this.PredefinedComponentsMap = {};

        staticComponents.forEach(c => this.PredefinedComponentsMap[c.OID] = c);
        issueInformationComponents.forEach(c => this.PredefinedComponentsMap[c.OID] = c);
        documentInformation.forEach(c => this.PredefinedComponentsMap[c.OID] = c);

        /**
         * @type {{Groups: ComponentGroup[]}}
         */
        this.PredefinedComponents = {
            Groups: [
                new global.PdfDesigner.Model.ComponentGroup(
                    'static',
                    i18next.t('changeMode.pdfDesigner.components.static.label'),
                    staticComponents
                ),
                new global.PdfDesigner.Model.ComponentGroup(
                    'document-information',
                    i18next.t('changeMode.pdfDesigner.components.documentInformation.label'),
                    documentInformation
                ),
                new global.PdfDesigner.Model.ComponentGroup(
                    'issue-information',
                    i18next.t('changeMode.pdfDesigner.components.issueInformation.label'),
                    issueInformationComponents
                )
            ]
        };
    }

    /**
     * Initialisiert die Prüfpunkt-Komponenten abhängig vom gewählten Formular
     */
    function initCheckpointComponents() {
        this.CheckpointComponents = { Groups: [] };
        this.CheckpointComponentsMap = {};
        this.CheckpointMap = {};

        if (!this.options.Form || !(this.options.Form.Parametergroups || []).length) {
            return;
        }

        this.options.Form.Parametergroups
            .forEach(function (group) {
                if (!group.Enabled || !(group.Parameters || []).length) {
                    return;
                }

                const components = [];

                group.Parameters.forEach(function (checkpoint) {
                    if (!checkpoint.Enabled) {
                        return;
                    }

                    if (checkpoint.Type === Enums.elementType.EMailAddresses && !Tools.IsEMailCpEnabled()) {
                        return;
                    }

                    if (checkpoint.Type === Enums.elementType.Print) {
                        return;
                    }

                    const component = createComponentFromCheckpoint.call(this, checkpoint);

                    if (!component) {
                        return;
                    }

                    components.push(component);

                    this.CheckpointMap[checkpoint.OID] = checkpoint;
                    this.CheckpointComponentsMap[component.OID] = component;
                }, this);

                if (components.length) {
                    const componentGroup = new global.PdfDesigner.Model.ComponentGroup(
                        group.OID,
                        group.Title,
                        components
                    );

                    this.CheckpointComponents.Groups.push(componentGroup);
                }
            }, this);
    }

    /**
     * @param {object} checkpoint
     * @return {CheckpointBaseComponent|null}
     */
    function createComponentFromCheckpoint(checkpoint) {
        if (!checkpoint) {
            return null;
        }

        const componentSettings = new global.PdfDesigner.Model.ComponentSettings({
            IsTemplate: true,
            OID: uuid()
        });

        switch (checkpoint.Type) {
            case Enums.elementType.Checkbox:
                return new global.PdfDesigner.Model.CheckboxCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Number:
                return new global.PdfDesigner.Model.NumberCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Line:
                return new global.PdfDesigner.Model.LineCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Memo:
                return new global.PdfDesigner.Model.MemoCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Date:
                return new global.PdfDesigner.Model.DateCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Time:
                return new global.PdfDesigner.Model.TimeCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Photo:
                return new global.PdfDesigner.Model.PhotoCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Scancode:
                return new global.PdfDesigner.Model.ScanCodeCpComponent(checkpoint, componentSettings);
            case Enums.elementType.LocationCode:
                return new global.PdfDesigner.Model.LocationCodeCpComponent(checkpoint, componentSettings);
            case Enums.elementType.ListBox:
                return new global.PdfDesigner.Model.ListBoxCpComponent(checkpoint, componentSettings);
            case Enums.elementType.MultiListBox:
                return new global.PdfDesigner.Model.MultiListBoxCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Info:
                return new global.PdfDesigner.Model.InfoCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Signature:
                return new global.PdfDesigner.Model.SignatureCpComponent(checkpoint, componentSettings);
            case Enums.elementType.UsersAndTeams:
                return new global.PdfDesigner.Model.UserAndTeamsCpComponent(checkpoint, componentSettings);
            case Enums.elementType.IndividualData:
                return new global.PdfDesigner.Model.IndividualDataCpComponent(checkpoint, componentSettings);
            case Enums.elementType.PhoneNumber:
                return new global.PdfDesigner.Model.PhoneNumberCpComponent(checkpoint, componentSettings);
            case Enums.elementType.EMailAddresses:
                return new global.PdfDesigner.Model.EMailAddressesCpComponent(checkpoint, componentSettings);
            case Enums.elementType.Files:
                return new global.PdfDesigner.Model.FilesCpComponent(checkpoint, componentSettings);
        }
    }

    /**
     * Handelt das PageClick-Event außerhalb von Komponenten
     */
    function onPageClick() {
        unselectActiveComponent.call(this);
    }

    /**
     * Handelt das Click-Event für Komponenten auf Seiten
     * @param {BaseComponent} component
     */
    function onPageComponentClick(component) {
        if (this.ActiveComponent) {
            this.ActiveComponent.UnSelect();
        }

        this.ActiveComponent = component;
    }

    /**
     * Handelt das Bearbeiten von Komponenten in Kopf-/Fußzeilen
     * @param {BaseComponent} component
     */
    function onMarginalComponentEdit(component) {
        if (!component) {
            return;
        }

        refreshMarginalComponent.call(this, component);
    }

    /**
     * Handelt das Löschen von Page-Komponenten
     */
    function onPageComponentRemove() {
        unselectActiveComponent.call(this);
    }

    /**
     * Deaktiviert die Selektion der aktuell aktiven Komponente
     */
    function unselectActiveComponent() {
        if (this.ActiveComponent) {
            this.ActiveComponent.UnSelect();
        }

        this.ActiveComponent = null;
        this.$content.find('.component-settings').empty();
    }

    PdfDesigner.prototype = Object.create(global.PopupBase.prototype, {
        constructor: PdfDesigner
    });

    PdfDesigner.prototype.CreateContentMarkup = function () {
        return [
            '<div class="component-list-switch">',
                `<div class="switch active" data-tab="predefined">${i18next.t('changeMode.pdfDesigner.components.tabs.predefined')}</div>`,
                `<div class="switch" data-tab="checkpoints">${i18next.t('changeMode.pdfDesigner.components.tabs.checkpoints')}</div>`,
            '</div>',
            '<div class="components">',
                '<div class="search">',
                    `<input type="search" placeholder="${i18next.t('changeMode.pdfDesigner.components.search.placeholder')}">`,
                '</div>',
                '<div class="component-tab" data-tab="predefined">',
                    this.PredefinedComponents.Groups
                        .map(group => group.RenderInSelection())
                        .join(''),
                '</div>',
                '<div class="component-tab hide" data-tab="checkpoints">',
                    this.CheckpointComponents.Groups
                        .map(group => group.RenderInSelection())
                        .join(''),
                '</div>',
            '</div>',
            '<div class="section-navigation">',
                '<ul class="tab-view-tabs tab-view nav">',
                    this.Sections
                        .map((_, idx) => {
                            return `<li data-idx="${idx}"${idx === 0 ? ' class="active"' : ''}>${idx + 1}</li>`;
                        })
                        .join(''),
                    `<li data-action="new-section">+ ${i18next.t('changeMode.pdfDesigner.sections.newSection')}</li>`,
                '</ul>',
            '</div>',
            '<div class="section-wrapper">',
                '<div class="section-toolbar">',
                    '<div class="multi-button-selection">',
                        `<span class="multi-button-selection-button button button-steal" data-modification-area="header" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.editHeader')}">`,
                            '<img src="./img/header.svg" />',
                        '</span>',
                        `<span class="multi-button-selection-button button button-steal active" data-modification-area="page" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.editPage')}">`,
                            '<img src="./img/paper.svg">',
                        '</span>',
                        `<span class="multi-button-selection-button button button-steal" data-modification-area="footer" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.editFooter')}">`,
                            '<img src="./img/footer.svg">',
                        '</span>',
                    '</div>',
                    '<div class="flex-dummy"></div>',
                    `<div class="button-steal with-icon add-page" title="${i18next.t('changeMode.pdfDesigner.sectionToolbar.newPage')}">`,
                        '<div class="icon"><img src="./img/paper.svg"></div>',
                        `<div class="caption">+ ${i18next.t('changeMode.pdfDesigner.sectionToolbar.newPage')}</div>`,
                    '</div>',
                '</div>',
                '<div class="canvas-wrapper page-active">',
                    this.CurrentSection.Render(),
                '</div>',
            '</div>',
            '<div class="component-settings"></div>'
        ].join('');
    };

    PdfDesigner.prototype.BindAdditionalEvents = function () {
        this.$content.find('.switch[data-tab]').on('click', $.proxy(onSwitchTab, this));
        this.$content.find('.search input').on('input', $.proxy(onSearchInput, this));
        this.$content.find('.section-header').on('click', $.proxy(onComponentsHeaderClick, this));
        this.$content.find('.section-navigation').on('click', 'li[data-idx]', $.proxy(onSelectSectionClick, this));
        this.$content.find('.section-navigation').on('click', 'li[data-action="new-section"]', $.proxy(onAddSectionClick, this));
        this.$content.find('.section-toolbar').on('click', '.button[data-modification-area]', $.proxy(onSelectSectionModificationArea, this));
        this.$content.find('.section-toolbar').on('click', '.add-page', $.proxy(onAddPageClick, this));

        initPrototypeInteractEvents.call(this);
        initPageInteractEvents.call(this);
        initMarginalInteractEvents.call(this);
    };

    PdfDesigner.prototype.OnAfterRendered = function () {
        renderPages.call(this);

        initScrollbar(this.$content.find('.component-tab'));
        initScrollbar(this.$content.find('.canvas-wrapper'));
    };

    PdfDesigner.prototype.OnResize = function () {
        this.Pages.forEach(page => page.DetermineDimensions());
    };

    PdfDesigner.prototype.OnAfterClosed = function () {
        unsetInteractEvents.call(this);
    };

    /**
     * Rendert alle Seiten des aktuell ausgewählten Bereichs
     */
    function renderPages() {
        // Seiten positionieren
        this.$content.find('.canvas').toArray().forEach(function (page) {
            const $page = $(page);
            const pageNo = $page.data('page-no');
            let top = 25 + (1122 * (pageNo - 1));

            if (pageNo > 1) {
                top += (pageNo - 1) * 25;
            }

            $page.css('top', top + 'px');
        });

        // Content-Bereiche erstellen und Main-Komponenten rendern
        this.CurrentSection.Segments.forEach(page => {
            page.DetermineDimensions()
                .RenderHeader()
                .RenderMainArea()
                .RenderFooter()
                .PlaceComponents()
                .BindEvents();
        });

        // Kopfzeilen rendern
        (this.CurrentSection.Header?.Content || []).forEach(component => {
            this.CurrentSection.Segments.forEach(page =>
                component.RenderOnPage(page, global.PdfDesigner.Model.Enums.SectionModificationArea.Header)
            );
        }, this);

        // Fußzeilen rendern
        (this.CurrentSection.Footer?.Content || []).forEach(component => {
            this.CurrentSection.Segments.forEach(page =>
                component.RenderOnPage(page, global.PdfDesigner.Model.Enums.SectionModificationArea.Footer)
            );
        }, this);
    }

    /**
     * Initialisiert eine OverlayScrollbars-Instanz für den angegebenen Container
     * @param {$|HTMLElement} container
     * @param {number|null} yScrollPosition
     */
    function initScrollbar(container, yScrollPosition) {
        if ((container instanceof $)) {
            container = container[0];
        }

        let instance = OverlayScrollbarsGlobal.OverlayScrollbars(container);

        if (instance) {
            instance.destroy();
        }

        instance = OverlayScrollbarsGlobal.OverlayScrollbars(container, {
            scrollbars: {
                theme: 'os-theme-dark',
                autoHide: 'never'
            }
        });

        if (typeof yScrollPosition === 'number' && !isNaN(yScrollPosition)) {
            instance.elements().viewport.scroll({ top: yScrollPosition });
        }
    }

    /**
     * Gibt, sofern vorhanden, eine OverlayScrollbars-Instanz für den angegebenen Container zurück
     * @param {$|HTMLElement} container
     * @return {*}
     */
    function getScrollbar(container) {
        if ((container instanceof $)) {
            container = container[0];
        }

        return OverlayScrollbarsGlobal.OverlayScrollbars(container);
    }

    /**
     * Resetted alle Interact-Events
     */
    function unsetInteractEvents() {
        this.Interacts.prototypes.draggable.unset();
        this.Interacts.prototypes.droppable.unset();
        this.Interacts.pages.draggable.unset();
        this.Interacts.header.resizable.unset();
        this.Interacts.footer.resizable.unset();
    }

    /**
     * Erstellt die Interact-Events für Prototyp-Komponenten aus der linken Seitenleiste
     */
    function initPrototypeInteractEvents() {
        const me = this;
        let currentComponentPrototype;
        let $prototypeElement;
        let $prototypeMoveElement;

        me.Interacts.prototypes.draggable = interact(`#${this.options.ID} .component-list li`)
            .draggable({
                inertia: false,
                autoScroll: false,
                modifiers: [
                    interact.modifiers.restrict({
                        restriction: '.content',
                        endOnly: true
                    })
                ],
                listeners: {
                    start: function (evt) {
                        $prototypeElement = $(evt.currentTarget);

                        const identifier = $prototypeElement.attr('id');

                        currentComponentPrototype = me.CurrentComponentTab === 'predefined' ?
                            me.PredefinedComponentsMap[identifier] :
                            me.CheckpointComponentsMap[identifier];

                        if (!currentComponentPrototype) {
                            return;
                        }

                        $prototypeElement.addClass('move-active');

                        const moveElementId = me.options.ID + '-prototype-clone';

                        $prototypeMoveElement = currentComponentPrototype.CreateDragElement();
                        $prototypeMoveElement.attr('id', moveElementId);

                        me.$content.append($prototypeMoveElement);

                        const componentsPosition = $prototypeElement.closest('.components').position();

                        $prototypeMoveElement
                            .css({
                                left: evt.clientX - evt.rect.left - 2,
                                top: evt.clientY - componentsPosition.top - parseFloat(me.$content.css('padding-top')) - 25
                            })
                            .data({
                                x: evt.clientX - evt.rect.left - 2,
                                y: evt.clientY - componentsPosition.top - parseFloat(me.$content.css('padding-top')) - 25
                            });
                    },
                    move: function (evt) {
                        if (!($prototypeMoveElement instanceof $)) {
                            return;
                        }

                        const x = ($prototypeMoveElement.data('x') || 0) + evt.dx;
                        const y = ($prototypeMoveElement.data('y') || 0) + evt.dy;

                        $prototypeMoveElement
                            .css({ top: y, left: x })
                            .data({ 'x': x, 'y': y });
                    },
                    end: function () {
                        if (!($prototypeElement instanceof $)) {
                            return;
                        }

                        $prototypeElement.removeClass('move-active');

                        if (!($prototypeMoveElement instanceof $)) {
                            return;
                        }

                        $prototypeMoveElement.remove();

                        currentComponentPrototype = null;
                        $prototypeElement = null;
                        $prototypeMoveElement = null;
                    }
                }
            });

        let dropTarget = `#${this.options.ID} `;

        switch (this.SectionModificationArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                dropTarget += '.page-header';
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                dropTarget += '.main-area';
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                dropTarget += '.page-footer';
                break;
        }

        me.Interacts.prototypes.droppable = interact(dropTarget)
            .dropzone({
                accept: '.component-prototype',
                overlap: 'pointer',
                ondrop: function (evt) {
                    if (!($prototypeMoveElement instanceof $)) {
                        return;
                    }

                    const $dropZoneElement = $(evt.currentTarget);
                    const pageNo = $dropZoneElement.hasClass('page') ?
                        $dropZoneElement.data('page-no') :
                        $dropZoneElement.closest('.page').data('page-no');
                    const page = me.CurrentSection.GetPage(pageNo - 1);
                    const position = $prototypeMoveElement.position();
                    const pagePosition = $dropZoneElement.hasClass('page') ?
                        $dropZoneElement.position() :
                        $dropZoneElement.closest('.page').position();
                    const $sectionWrapper = $dropZoneElement.closest('.section-wrapper');
                    const sectionWrapperPositionTop = parseFloat($sectionWrapper.css('top'));
                    const $canvasWrapper = $dropZoneElement.closest('.canvas-wrapper');
                    const canvasWrapperPositionTop = parseFloat($canvasWrapper.css('top'));

                    const coordinates = {
                        left: position.left - pagePosition.left + 95,
                        top: position.top - pagePosition.top - sectionWrapperPositionTop - canvasWrapperPositionTop - parseFloat(me.$content.css('padding-top')) + 5
                    };

                    const relativeCoordinates = {
                        left: coordinates.left / page.Width * 100,
                        top: coordinates.top / page.Height * 100
                    };

                    currentComponentPrototype.CreateDerivedInstance(
                        relativeCoordinates.left,
                        relativeCoordinates.top
                    )
                    .then((newPageComponent) => {
                        switch (me.SectionModificationArea) {
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                                me.CurrentSection.Header.AddComponent(newPageComponent);
                                me.CurrentSection.Segments.forEach(page =>
                                    newPageComponent.RenderOnPage(
                                        page,
                                        global.PdfDesigner.Model.Enums.SectionModificationArea.Header
                                    )
                                );
                                break;
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                                page.AddComponent(newPageComponent);
                                newPageComponent.RenderOnPage(page, me.SectionModificationArea);
                                break;
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                                me.CurrentSection.Footer.AddComponent(newPageComponent);
                                me.CurrentSection.Segments.forEach(page =>
                                    newPageComponent.RenderOnPage(
                                        page,
                                        global.PdfDesigner.Model.Enums.SectionModificationArea.Footer
                                    )
                                );
                                break;
                        }

                        me.$content.find('.component-settings').html(newPageComponent.RenderSettings());
                    });
                }
            });
    }

    /**
     * Erstellt die Interact-Events für auf den Seiten platzierte Komponenten
     */
    function initPageInteractEvents() {
        const me = this;
        let page;
        let component;

        let draggableTarget = `#${this.options.ID} `;

        switch (this.SectionModificationArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                draggableTarget += '.page-header';
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                draggableTarget += '.main-area';
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                draggableTarget += '.page-footer';
                break;
        }

        draggableTarget += ' .component';

        me.Interacts.pages.draggable = interact(draggableTarget)
            .draggable({
                inertia: false,
                autoScroll: false,
                modifiers: [
                    interact.modifiers.restrict({
                        restriction: 'parent',
                        elementRect: { left: 0, right: 1, top: 0, bottom: 1 },
                    })
                ],
                listeners: {
                    start: function (evt) {
                        page = initPageComponentInteraction.call(me, evt);

                        const $component = $(evt.currentTarget);
                        const identifier = $component.data('identifier');

                        switch (me.SectionModificationArea) {
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                                component = me.CurrentSection.Header.GetComponent(identifier);
                                break;
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                                component = page.GetComponent(identifier);
                                break;
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                                component = me.CurrentSection.Footer.GetComponent(identifier);
                                break;
                        }
                    },
                    move: function (evt) {
                        if (!component) {
                            page = null;
                            return;
                        }

                        const $component = $(evt.currentTarget);
                        const x = ($component.data('x') || 0) + evt.dx;
                        const y = ($component.data('y') || 0) + evt.dy;

                        $component
                            .css({ top: y, left: x })
                            .data({ 'x': x, 'y': y });
                    },
                    end: function (evt) {
                        if (!component || !page) {
                            component = null;
                            page = null;

                            return;
                        }

                        endPageComponentInteraction.call(me, evt, component, page);

                        page = null;
                        component = null;
                    }
                }
            })
            .resizable({
                edges: { top: true, left: true, bottom: true, right: true },
                modifiers: [
                    interact.modifiers.restrict({
                        restriction: 'parent',
                    }),
                    interact.modifiers.restrictSize({
                        min: { width: 50, height: 20 },
                    }),
                ],
                inertia: false,
                listeners: [{
                    start: function (evt) {
                        page = initPageComponentInteraction.call(me, evt);

                        const $component = $(evt.currentTarget);
                        const identifier = $component.data('identifier');

                        switch (me.SectionModificationArea) {
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                                component = me.CurrentSection.Header.GetComponent(identifier);
                                break;
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                                component = page.GetComponent(identifier);
                                break;
                            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                                component = me.CurrentSection.Footer.GetComponent(identifier);
                                break;
                        }
                    },
                    move: function (evt) {
                        const $component = $(evt.currentTarget);

                        $component.css({
                            'width': evt.rect.width,
                            'height': evt.rect.height
                        });

                        const x = ($component.data('x') || 0) + evt.deltaRect.left;
                        const y = ($component.data('y') || 0) + evt.deltaRect.top;

                        $component
                            .css({ top: y, left: x })
                            .data({ 'x': x, 'y': y });
                    },
                    end: function (evt) {
                        if (!component || !page) {
                            component = null;
                            page = null;

                            return;
                        }

                        endPageComponentInteraction.call(me, evt, component, page);

                        page = null;
                        component = null;
                    }
                }]
            });
    }

    /**
     * Wird beim Start einer Komponenten-Interaktion innerhalb einer Seite ausgeführt
     * @param evt
     * @return {Page|null}
     */
    function initPageComponentInteraction(evt) {
        const $component = $(evt.currentTarget);

        $component.css('border-color', 'orange orange orange #333');

        const componentsPosition = $component.position();

        $component
            .css({
                left: componentsPosition.left,
                top: componentsPosition.top
            })
            .data({
                x: componentsPosition.left,
                y: componentsPosition.top
            });

        const pageNo = $component.closest('.page').data('page-no');

        return this.CurrentSection.GetPage(pageNo - 1);
    }

    /**
     * Wird beim Beenden einer Komponenten-Interaktion innerhalb einer Seite ausgeführt
     * @param evt
     * @param {BaseComponent} component
     * @param {Page} page
     */
    function endPageComponentInteraction(evt, component, page) {
        const $component = $(evt.currentTarget);
        const coordinates = $component.position();

        const relativeCoordinates = {
            left: coordinates.left / page.Width * 100,
            top: coordinates.top / page.Height * 100
        };

        const relativeDimensions = {
            height: $component.outerHeight() / page.Height * 100,
            width: $component.outerWidth() / page.Width * 100
        };

        component
            .SetCoordinates(relativeCoordinates.left, relativeCoordinates.top)
            .SetDimensions(relativeDimensions.height, relativeDimensions.width);

        $component.css('border-color', '');

        refreshMarginalComponent.call(this, component);
    }

    /**
     * Erstellt die Interact-Events für Höhenanpassungen von Kopf- und Fußzeilen
     */
    function initMarginalInteractEvents() {
        const defaultResizableSettings = {
            modifiers: [
                interact.modifiers.restrictSize({
                    min: { height: 50 },
                    max: { height: 300 }
                }),
            ],
            inertia: false,
            listeners: [{
                move: function (evt) {
                    const $marginal = $(evt.currentTarget);

                    $marginal.css({
                        'height': evt.rect.height
                    });

                    const $page = $marginal.siblings('.main-area');
                    const $siblingMarginal = $marginal.hasClass('page-header') ?
                        $page.siblings('.page-footer') :
                        $page.siblings('.page-header');

                    $page.css('height', `calc(100% - ${$marginal.outerHeight()}px - ${$siblingMarginal.outerHeight()}px)`);
                },
                end: (evt) => {
                    const $marginal = $(evt.currentTarget);
                    const pageNo = $marginal.closest('.page').data('page-no');
                    const page = this.CurrentSection.GetPage(pageNo - 1);
                    const relativeHeight = $marginal.outerHeight() / page.Height * 100;
                    const marginal = $marginal.hasClass('page-header') ?
                        this.CurrentSection.Header :
                        this.CurrentSection.Footer;

                    // Höhe an Kopf- bzw. Fußzeile setzen
                    marginal.SetHeight(relativeHeight);

                    // Aktuelle Scrollposition merken
                    const $canvasWrapper = this.$content.find('.canvas-wrapper');
                    const scrollbar = getScrollbar($canvasWrapper);
                    const currentScrollPosition = scrollbar.elements().viewport.scrollTop;

                    // Komponenten neu anordnen
                    const realignmentResult = this.CurrentSection.TryRealignComponents();

                    if (realignmentResult.FailedToPosition > 0) {
                        var options = {
                            title: i18next.t('changeMode.pdfDesigner.components.realignmentFailed.title'),
                            text: i18next.t('changeMode.pdfDesigner.components.realignmentFailed.text', {
                                count: realignmentResult.FailedToPosition
                            }),
                            ok: true
                        };

                        Tools.Message.Show(options);
                    }

                    // Bereich neu rendern, wenn mindestens eine Komponente neu positioniert wurde
                    if (realignmentResult.OutOfPosition > 0 &&
                        realignmentResult.FailedToPosition < realignmentResult.OutOfPosition)
                    {
                        renderCurrentSection.call(this);
                        initScrollbar(this.$content.find('.canvas-wrapper'), currentScrollPosition);
                    }
                }
            }]
        };

        this.Interacts.header.resizable = interact('.page-header')
            .resizable({ ...defaultResizableSettings, edges: { top: true, left: false, bottom: true, right: false } });

        this.Interacts.footer.resizable = interact('.page-footer')
            .resizable({ ...defaultResizableSettings, edges: { top: true, left: false, bottom: true, right: false } });
    }

    /**
     * Rendert eine Komponente auf den Kopf-/Fußzeilen aller Seiten neu
     * @param {BaseComponent} component
     */
    function refreshMarginalComponent(component) {
        if (!component) {
            return;
        }

        switch (component.RenderArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                this.CurrentSection.Segments.forEach(page =>
                    component.RenderOnPage(page, global.PdfDesigner.Model.Enums.SectionModificationArea.Header)
                );
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                this.CurrentSection.Segments.forEach(page =>
                    component.RenderOnPage(page, global.PdfDesigner.Model.Enums.SectionModificationArea.Footer)
                );
                break;
        }
    }

    /**
     * Handelt das Click-Event für das Wechseln eines Tabs innerhalb der Komponenten-Auswahl
     * @param {MouseEvent} evt
     */
    function onSwitchTab(evt) {
        const $tabControlItem = $(evt.currentTarget);
        const tabId = $tabControlItem.data('tab');

        if (!tabId) {
            return;
        }

        $tabControlItem
            .addClass('active')
            .siblings()
            .removeClass('active');

        const $tab = this.$content.find(`.component-tab[data-tab="${tabId}"]`);

        $tab
            .removeClass('hide')
            .siblings('.component-tab')
            .addClass('hide');

        initScrollbar($tab);

        this.CurrentComponentTab = tabId;
    }

    /**
     * Handelt das Input-Event für das Durchführen einer Suche innerhalb der Komponenten-Auswahl
     * @param {KeyboardEvent} evt
     */
    function onSearchInput(evt) {
        const $input = $(evt.currentTarget);

        function fn() {
            if (evt.isTrigger) {
                return;
            }

            const searchText = $.trim($input.val());
            const componentGroups = this.CurrentComponentTab === 'predefined' ?
                this.PredefinedComponents.Groups :
                this.CheckpointComponents.Groups;
            const $container = this.$content.find(`.component-tab[data-tab="${this.CurrentComponentTab}"]`);

            setComponentVisibility(searchText, componentGroups, $container);
        }

        window.clearTimeout(this.SearchTimeout);

        if (evt.which === Enums.KeyCodes.Enter || evt.isTrigger) {
            fn.call(this);
        } else {
            this.SearchTimeout = window.setTimeout(() => fn.call(this), 350);
        }
    }

    /**
     * @param {string|null} searchText
     * @param {ComponentGroup[]} componentGroups
     * @param {$} $container
     */
    function setComponentVisibility(searchText, componentGroups, $container) {
        if (!(componentGroups instanceof Array) || !componentGroups.length) {
            return;
        }

        if (!($container instanceof $)) {
            return;
        }

        const regEx = !!searchText ?
            new RegExp(Tools.escRegExp(searchText), 'ig') :
            null;

        componentGroups.forEach(group => {
            let groupIsVisible = false;

            group.Components.forEach(component => {
                component.IsVisible = !regEx || regEx.test(component.Title);
                groupIsVisible = groupIsVisible || component.IsVisible;

                if (regEx) {
                    regEx.lastIndex = -1;
                }

                $container.find(`.component-prototype[data-identifier="${component.OID}"]`)
                    .toggleClass('hide', !component.IsVisible);
            });

            group.IsVisible = !!groupIsVisible;

            $container.find(`section[data-identifier="${group.Identifier}"]`)
                .toggleClass('hide', !group.IsVisible);
        });
    }

    /**
     * Handelt das Click-Event für den Kopfbereich von Komponentengruppen
     * @param {MouseEvent} evt
     */
    function onComponentsHeaderClick(evt) {
        const $header = $(evt.currentTarget);

        $header.toggleClass('collapsed');
        $header.siblings('.component-list').toggleClass('hide');
    }

    /**
     * Handelt das Click-Event für die Bereichsauswahl
     * @param {MouseEvent} evt
     */
    function onSelectSectionClick(evt) {
        const $section = $(evt.currentTarget);
        const sectionIdx = $section.data('idx');

        if (sectionIdx == null || isNaN(sectionIdx)) {
            return;
        }

        selectAndRenderSection.call(this, sectionIdx);
    }

    /**
     * Handelt das Click-Event zum Hinzufügen eines neuen Bereichs
     * @param {MouseEvent} evt
     */
    function onAddSectionClick(evt) {
        createNewSection.call(this);

        const newIdx = this.Sections.length - 1;

        $(evt.currentTarget).before(`<li data-idx="${newIdx}">${newIdx + 1}</li>`);

        selectAndRenderSection.call(this, newIdx);
    }

    /**
     * Selektiert einen Bereich anhand des angegebenen Indizes, rendert diesen und erstellt die nötigen Events
     * @param {number} sectionIdx
     */
    function selectAndRenderSection(sectionIdx) {
        if (this.Sections.length < sectionIdx) {
            return;
        }

        unselectActiveComponent.call(this);

        this.CurrentSection = this.Sections[sectionIdx];

        this.$content
            .find(`.section-navigation li[data-idx="${sectionIdx}"]`)
            .addClass('active')
            .siblings()
            .removeClass('active');

        renderCurrentSection.call(this);
    }

    /**
     * Rendert den aktuell ausgewählten Bereich neu und erstellt die nötigen Events
     */
    function renderCurrentSection() {
        this.$content.find('.canvas-wrapper').html(this.CurrentSection.Render());

        renderPages.call(this);
        initScrollbar(this.$content.find('.canvas-wrapper'));
        unsetInteractEvents.call(this);
        initPrototypeInteractEvents.call(this);
        initPageInteractEvents.call(this);
        initMarginalInteractEvents.call(this);
    }

    /**
     * Erstellt einen neuen Bereich
     */
    function createNewSection() {
        const page = createNewPage.call(this, 1);
        const sectionSettings = getDefaultSectionSettings();
        const marginalSettings = getDefaultMarginalSettings.call(this);

        this.Sections.push(
            new global.PdfDesigner.Model.Section(
                sectionSettings,
                null,
                new global.PdfDesigner.Model.Marginal(
                    marginalSettings,
                    10,
                    global.PdfDesigner.Model.Enums.SectionModificationArea.Header
                ),
                new global.PdfDesigner.Model.Marginal(
                    marginalSettings,
                    10,
                    global.PdfDesigner.Model.Enums.SectionModificationArea.Footer
                ),
                [page]
            )
        );
    }

    /**
     * Erstellt eine neue Seite
     * @param {number} pageNo
     * @return {Page}
     */
    function createNewPage(pageNo) {
        const pageSettings = getDefaultPageSettings.call(this);

        return new global.PdfDesigner.Model.Page(pageSettings, pageNo);
    }

    /**
     * Handelt das Click-Event zur Auswahl des zu bearbeitenden Seitenbereichs
     * @param {MouseEvent} evt
     */
    function onSelectSectionModificationArea(evt) {
        const $btn = $(evt.currentTarget);

        this.SectionModificationArea = $btn.data('modification-area');

        $btn
            .addClass('active')
            .siblings()
            .removeClass('active');

        const $canvasWrapper = this.$content.find('.canvas-wrapper');

        $canvasWrapper.removeClass('header-active page-active footer-active');

        switch (this.SectionModificationArea) {
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Header:
                $canvasWrapper.addClass('header-active');
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Page:
                $canvasWrapper.addClass('page-active');
                break;
            case global.PdfDesigner.Model.Enums.SectionModificationArea.Footer:
                $canvasWrapper.addClass('footer-active');
                break;
        }

        unsetInteractEvents.call(this);
        initPrototypeInteractEvents.call(this);
        initPageInteractEvents.call(this);
        initMarginalInteractEvents.call(this);
    }

    /**
     * Handelt das Click-Event zur Erstellung einer neuen Seite innerhalb des aktuellen Bereichs
     */
    function onAddPageClick() {
        const sectionPageCount = this.CurrentSection.Segments.length;
        const page = createNewPage.call(this, sectionPageCount + 1);

        this.CurrentSection.AddPage(page);

        const $canvasWrapper = this.$content.find('.canvas-wrapper');

        $canvasWrapper.html(this.CurrentSection.Render());

        renderPages.call(this);

        initScrollbar(this.$content.find('.canvas-wrapper'));
        unsetInteractEvents.call(this);
        initPrototypeInteractEvents.call(this);
        initPageInteractEvents.call(this);
        initMarginalInteractEvents.call(this);

        const $page = this.$content.find(`.page[data-page-no="${sectionPageCount + 1}"]`);
        const scrollbar = getScrollbar($canvasWrapper);
        const pageYPosition = parseInt($page.css('top')) - 5;

        scrollbar.elements().viewport.scroll({ top: pageYPosition });
    }

    if (!global.PdfDesigner) {
        global.PdfDesigner = {};
    }

    global.PdfDesigner.Window = PdfDesigner;
})(Modifications.Popups || (Modifications.Popups = {}));