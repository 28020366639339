/**
 * @require ./pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @abstract
     * @typedef {BaseComponent}
     */
    global.Model.BaseComponent = (function () {
        /**
         * @param {string} type
         * @param {string} title
         * @param {string|null} description
         * @param {string} icon
         * @param {ComponentSettings} settings
         * @constructor
         */
        function BaseComponent(type, title, description, icon, settings) {
            if (this.constructor === BaseComponent) {
                throw new Error('This class cannot be instanciated.');
            }

            /**
             * @readonly
             */
            this.CommonSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.position.label'),
                    CollapsedByDefault: true,
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.position.top'),
                            Key: 'Position.Coordinates.Y',
                            Type: global.Model.Enums.ComponentRepresentation.Input,
                            SubType: 'number',
                            Min: 0,
                            Max: 100
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.position.left'),
                            Key: 'Position.Coordinates.X',
                            Type: global.Model.Enums.ComponentRepresentation.Input,
                            SubType: 'number',
                            Min: 0,
                            Max: 100
                        }
                    ]
                },
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.dimensions.label'),
                    CollapsedByDefault: true,
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.dimensions.height'),
                            Key: 'Height',
                            Type: global.Model.Enums.ComponentRepresentation.Input,
                            SubType: 'number',
                            Min: 0,
                            Max: 100
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.dimensions.width'),
                            Key: 'Width',
                            Type: global.Model.Enums.ComponentRepresentation.Input,
                            SubType: 'number',
                            Min: 0,
                            Max: 100
                        }
                    ]
                }
            ];

            /**
             * @property
             * @type {string}
             * @readonly
             */
            this.Type = type;

            /**
             * @type {string}
             * @readonly
             */
            this.Title = title;

            /**
             * @type {string}
             * @readonly
             */
            this.Description = description;

            /**
             * @type {string}
             * @readonly
             */
            this.Icon = icon;

            /**
             * @type {number|null}
             */
            this.ZIndex = 2;

            /**
             * @type {ComponentSettings}
             */
            this.Settings = settings;

            /**
             * @type {boolean}
             * @readonly
             */
            this.IsTemplate = this.Settings.IsTemplate;

            /**
             * @type {string}
             * @readonly
             */
            this.OID = this.IsTemplate ? uuid() : this.Settings.OID;

            /**
             * @type {string|null}
             * @readonly
             */
            this.Placeholder = this.IsTemplate ? this.Settings.Placeholder : null;

            /**
             * @readonly
             */
            this.DefaultDimensions = {
                Width: 20,
                Height: 5
            };

            this.SettingsMap = {};

            this.CommonSettings.forEach(function (settingsGroup) {
                (settingsGroup.Settings || []).forEach(function (setting) {
                    this.SettingsMap[setting.Key] = setting;
                }, this);
            }, this);

            this.Layout = {
                HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                VerticalAlignment: global.Model.Enums.VerticalAlignment.Center,
                Rotation: 0,
                Fitmethod: global.Model.Enums.FitMethod.Auto
            };

            (this.ComponentSettings || []).forEach(function (settingsGroup) {
                (settingsGroup.Settings || []).forEach(function (setting) {
                    this.SettingsMap[setting.Key] = setting;
                }, this);
            }, this);
        }

        BaseComponent.prototype.constructor = BaseComponent;

        BaseComponent.prototype.CreateSelectionMarkup = function () {
            const markup = [`<li class="component-prototype" id="${this.OID}" data-identifier="${this.OID}" data-type="${this.Type}">`];

            if (!!this.Icon) {
                markup.push(`<div class="icon-wrapper"><img src="./img/${this.Icon}" /></div>`);
            }

            markup.push(`<div class="text-wrapper"><strong>${this.Title}</strong>`);

            if (!!this.Description) {
                markup.push(`<p class="description">${this.Description}</p>`);
            }

            markup.push('</div></li>');

            return markup.join('');
        };

        BaseComponent.prototype.CreateDragElement = function () {
            const markup = [
                '<div id="', this.OID,'-prototype-mover" class="pdf-designer-prototype-mover">',
                    this.Title,
                '</div>'
            ].join('');

            return $(markup);
        };

        /**
         * @param {number} relativeX
         * @param {number} relativeY
         * @param {object?} additionalSettings
         * @return {Deferred}
         */
        BaseComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY, additionalSettings) {
            let settings = this.Settings || {};

            settings.OID = uuid();
            delete settings.IsTemplate;

            let component = new this.constructor(this.Title, this.Description, settings);

            if (additionalSettings) {
                component = Object.assign(component, additionalSettings);
            }

            component.Width = component.DefaultDimensions.Width;
            component.Height = component.DefaultDimensions.Height;

            component.Position = {
                Type: global.Model.Enums.PositionType.Absolute,
                Coordinates: {
                    X: relativeX,
                    Y: relativeY
                }
            };

            delete component.Title;
            delete component.Description;
            delete component.Icon;

            return $.Deferred().resolve(component);
        };

        /**
         * @param {Page|null} page
         * @param {string|null} renderArea
         * @return {$|null}
         */
        BaseComponent.prototype.RenderOnPage = function (page, renderArea) {
            if (!renderArea) {
                if (!this.RenderArea) {
                    console.warn('Missing area information');
                    return null;
                }

                renderArea = this.RenderArea;
            } else if (!this.RenderArea) {
                this.RenderArea = renderArea;
            }

            if (this.IsTemplate) {
                console.warn('A template cannot be rendered on a page');
                return null;
            }

            if (!(page instanceof global.Model.Page)) {
                if (!(this.Page instanceof global.Model.Page)) {
                    console.error('Page information missing');
                    return null;
                }

                page = this.Page;
            }

            const $page = $(page.PageSelector);

            if (!$page.length) {
                return null;
            }

            const positionOnPage = {
                x: (this.Position.Coordinates.X / 100) * page.Width,
                y: (this.Position.Coordinates.Y / 100) * page.Height
            };

            const dimensions = {
                height: (this.Height / 100) * page.Height,
                width: (this.Width / 100) * page.Width
            };

            let componentBoxStyle = 'position:absolute;';

            if (positionOnPage.x >= 0) {
                componentBoxStyle += `left:${positionOnPage.x}px;`;
            }

            if (positionOnPage.y >= 0) {
                componentBoxStyle += `top:${positionOnPage.y}px;`;
            }

            if (dimensions.height > 0) {
                componentBoxStyle += `height:${dimensions.height}px;`;
            }

            if (dimensions.width > 0) {
                componentBoxStyle += `width:${dimensions.width}px;`;
            }

            if (this.Textflow) {
                if (this.Textflow.Bold) {
                    componentBoxStyle += 'font-weight:bold;';
                }

                if (this.Textflow.Italic) {
                    componentBoxStyle += 'font-style:italic;'
                }

                if (this.Textflow.Underline) {
                    componentBoxStyle += 'text-decoration:underline;';
                }
            }

            if (this.Layout) {
                switch (this.Layout.HorizontalAlignment) {
                    case global.Model.Enums.HorizontalAlignment.Left:
                        componentBoxStyle += 'justify-content:flex-start;';
                        break;
                    case global.Model.Enums.HorizontalAlignment.Center:
                        componentBoxStyle += 'justify-content:center;';
                        break;
                    case global.Model.Enums.HorizontalAlignment.Right:
                        componentBoxStyle += 'justify-content:flex-end;text-align:right;';
                        break;
                    case global.Model.Enums.HorizontalAlignment.Justify:
                        componentBoxStyle += 'justify-content:flex-start;text-align:justify;';
                        break;
                }

                switch (this.Layout.VerticalAlignment) {
                    case global.Model.Enums.VerticalAlignment.Top:
                        componentBoxStyle += 'align-items:flex-start;';
                        break;
                    case global.Model.Enums.VerticalAlignment.Center:
                        componentBoxStyle += 'align-items:center;';
                        break;
                    case global.Model.Enums.VerticalAlignment.Bottom:
                        componentBoxStyle += 'align-items:flex-end;';
                        break;
                }
            }

            if (typeof this.ZIndex === 'number' && this.ZIndex > 0) {
                componentBoxStyle += `z-index:${this.ZIndex};`;
            }

            const $existingNode = $page.find(`.component[data-identifier="${this.OID}"]`);

            if ($existingNode.length) {
                $existingNode.remove();
            }

            const markup = [
                `<div class="component${!!this.IsActive ? ' active' : ''}" style="${componentBoxStyle}" data-identifier="${this.OID}">`,
                    this.CreateComponentContent(),
                '</div>'
            ].join('');

            let $target = $page;

            switch (renderArea) {
                case global.Model.Enums.SectionModificationArea.Header:
                    $target = $target.find('.page-header');
                    break;
                case global.Model.Enums.SectionModificationArea.Footer:
                    $target = $target.find('.page-footer');
                    break;
                default:
                    $target = $target.find('.main-area');
                    break;
            }

            $target.append(markup);

            const $node = $page.find(`.component[data-identifier="${this.OID}"]`);

            this.SetNode($node);

            $node.on('click.selectNode', $.proxy(this.OnComponentClick, this));
            $node.trigger('click');

            return $node;
        };

        /**
         * @return {string}
         */
        BaseComponent.prototype.CreateComponentContent = function () {
            return `<span style="color:#f00;">${Tools.escapeHtml(this.Title)}</span>`;
        };

        BaseComponent.prototype.RenderSettings = function () {
            const context = getPlacementArea.call(this);
            const $settings = $(context.Settings.SettingsContainerSelector);

            if (!$settings.length) {
                console.warn('settings container unavailable');
                return;
            }

            $settings.html(renderSettings.call(this));

            $settings.find('.section-header')
                .off('click')
                .on('click', $.proxy(onComponentsHeaderClick, this));

            $settings
                .off('input.changeValue')
                .on('input.changeValue', 'input, textarea, select', $.proxy(onChangeInputValue, this));

            $settings
                .off('click.selectFile')
                .on('click.selectFile', '.select-file', $.proxy(onFileSelectionClick, this));

            $settings
                .off('click.selectMultiSelectionButton')
                .on('click.selectMultiSelectionButton', '.multi-button-selection-button', $.proxy(onMultiButtonSelectionButtonClick, this));

            $settings
                .off('click.clickButton')
                .on('click.clickButton', '.button.btn-steal', $.proxy(onSettingsButtonClick, this));
        };

        BaseComponent.prototype.RemoveHelperInformation = function () {
            delete this.$node;
            delete this.Checkpoint;
            delete this.CommonSettings;
            delete this.ComponentSettings;
            delete this.DefaultDimensions;
            delete this.IsActive;
            delete this.IsTemplate;
            delete this.Marginal;
            delete this.Page;
            delete this.Placeholder;
            delete this.RenderArea;
            delete this.Settings;
            delete this.SettingsMap;
            delete this.ZIndex;
        };

        function getPlacementArea() {
            return this.RenderArea === global.Model.Enums.SectionModificationArea.Page ?
                this.Page :
                this.Marginal;
        }

        function renderSettings() {
            const settings = this.CommonSettings.concat(this.ComponentSettings || []);

            /**
             * @type Function
             */
            let removeAction;

            switch (this.RenderArea) {
                case global.Model.Enums.SectionModificationArea.Header:
                case global.Model.Enums.SectionModificationArea.Footer:
                    removeAction = $.proxy(this.Marginal.RemoveComponent, this.Marginal, this.OID);
                    break;
                case global.Model.Enums.SectionModificationArea.Page:
                    removeAction = $.proxy(this.Page.RemoveComponent, this.Page, this.OID);
                    break;
            }

            const actions = {
                Label: i18next.t('changeMode.pdfDesigner.settings.actions.label'),
                Settings: [{
                    Label: i18next.t('changeMode.pdfDesigner.settings.actions.deleteComponent'),
                    Type: global.Model.Enums.ComponentRepresentation.Button,
                    AdditionalClasses: ['btn-delete'],
                    HideLabel: true,
                    OnClick: removeAction,
                    Key: 'delete-component'
                }]
            };

            this.SettingsMap[actions.Settings[0].Key] = actions.Settings[0];

            settings.push(actions);

            return settings.map(function (settingsGroup) {
                return renderSettingsGroup.call(this, settingsGroup);
            }, this).join('');
        }

        function renderSettingsGroup(settingsGroup) {
            if (!settingsGroup) {
                return null;
            }

            return [
                `<div class="settings-section${settingsGroup.CollapsedByDefault ? ' collapsed' : ''}">`,
                    `<h4 class="section-header">${settingsGroup.Label}</h4>`,
                    '<ul class="settings-list">',
                        (settingsGroup.Settings || []).map(function (setting) {
                            return renderSetting.call(this, setting);
                        }, this).join(''),
                    '</ul>',
                '</div>',
            ].join('');
        }

        function renderSetting(setting) {
            if (!setting) {
                return null;
            }

            const markup = [
                `<li class="setting" data-key="${setting.Key}">`,
            ];

            if (!setting.HideLabel) {
                markup.push(`<label>${setting.Label}</label>`);
            }

            switch (setting.Type) {
                case global.Model.Enums.ComponentRepresentation.Boolean:
                    markup.push(renderBooleanSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentRepresentation.Dropdown:
                    markup.push(renderDropdownSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentRepresentation.FileSelection:
                    markup.push(renderFileSelectionSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentRepresentation.Info:
                    markup.push(renderInfoSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentRepresentation.Input:
                    markup.push(renderTextInputSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentRepresentation.MultiLineInput:
                    markup.push(renderTextAreaInputSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentRepresentation.MultiButtonSelection:
                    markup.push(renderMultiButtonSelectionSetting.call(this, setting));
                    break;
                case global.Model.Enums.ComponentRepresentation.Button:
                    markup.push(renderButtonSetting.call(this, setting));
                    break;
            }

            markup.push('</li>');

            return markup.join('');
        }

        function renderBooleanSetting(setting) {
            if (!setting) {
                return;
            }

            const value = Tools.GetValueByPath(setting.Key, this) || setting.Default;
            const markup = [
                '<div class="toggle-switch">',
                    `<input type="checkbox" id="${setting.Key}" ${!!value ? ' checked' : ''} />`,
                    `<label for="${setting.Key}">&nbsp;</label>`,
                '</div>'
            ];

            return markup.join('');
        }

        function renderDropdownSetting(setting) {
            if (!setting || !(setting.Options || []).length) {
                return;
            }

            const markup = [
                '<select>'
            ];

            const selectedValue = setting.GetFormattedDisplayValue instanceof Function ?
                setting.GetFormattedDisplayValue.call(setting, this) :
                Tools.GetValueByPath(setting.Key, this) || setting.Default;

            markup.push((setting.Options || []).map(function (option) {
                return `<option value="${option.Value}"${option.Value === selectedValue ? ' selected' : ''}>${option.Label}</option>`;
            }).join(''));

            markup.push('</select>');

            return markup.join('');
        }

        function renderFileSelectionSetting(setting) {
            if (!setting) {
                return;
            }

            const markup = [];
            const selectedFile = changemode.Files[this.FileOID];

            markup.push(
                `<p class="selected-file${selectedFile ? '' : ' hide'}">`,
                    (selectedFile ? Tools.escapeHtml(selectedFile.Title) : ''),
                '</p>'
            );

            markup.push(
                '<div class="btn-steal select-file">',
                    i18next.t('changeMode.pdfDesigner.settings.files.fileSelection.buttonCaption'),
                '</div>'
            );

            return markup.join('');
        }

        function renderTextInputSetting(setting) {
            if (!setting) {
                return null;
            }

            const markup = [];
            const value = Tools.GetValueByPath(setting.Key, this) || setting.Default;

            markup.push(`<input type="${setting.SubType}" value="${$.trim(Tools.escapeHtml(value))}"`);

            if (setting.SubType === 'number') {
                if (typeof (setting.Min) === 'number' && !isNaN(setting.Min)) {
                    markup.push(` min="${setting.Min}"`);
                }

                if (typeof (setting.Max) === 'number' && !isNaN(setting.Max)) {
                    markup.push(` max="${setting.Max}"`);
                }
            }

            markup.push('/>');

            return markup.join('');
        }

        function renderTextAreaInputSetting(setting) {
            if (!setting) {
                return null;
            }

            const markup = [];
            const value = Tools.GetValueByPath(setting.Key, this) || setting.Default;

            markup.push(`<textarea>${$.trim(Tools.escapeHtml(value))}</textarea>`);

            return markup.join('');
        }

        function renderInfoSetting(setting) {
            if (!setting) {
                return null;
            }

            const value = Tools.GetValueByPath(setting.Key, this);

            return `<p class="info">${value}</p>`;
        }

        function renderMultiButtonSelectionSetting(setting) {
            if (!setting) {
                return;
            }

            const value = !!setting.AllowMultiSelection ? null : Tools.GetValueByPath(setting.Key, this);
            const markup = [
                '<div class="multi-button-selection">',
                    setting.Options.map(function (option) {
                        const optionIsSelected = !!setting.AllowMultiSelection ?
                            !!Tools.GetValueByPath(`${setting.Key}.${option.Key}`, this) :
                            value === option.Value;

                        const markup = [
                            `<div class="multi-button-selection-button button-steal${optionIsSelected ? ' active' : ''}" title="${option.Caption}"`
                        ];

                        if (option.Value != null) {
                            markup.push(` data-value="${option.Value}"`);
                        }

                        if (!!option.Key) {
                            markup.push(` data-key="${option.Key}"`);
                        }

                        markup.push(
                            '>',
                                `<img src="${option.Icon}"${!!option.IconStyle ? ` style="${option.IconStyle}"` : ''} />`,
                            '</div>'
                        );

                        return markup.join('');
                    }, this).join(''),
                '</div>'
            ];

            return markup.join('');
        }

        function renderButtonSetting(setting) {
            if (!setting) {
                return;
            }

            return `<div class="button btn-steal${(setting.AdditionalClasses || []).length ? ` ${setting.AdditionalClasses.join(' ')}` : ''}">${setting.Label}</div>`;
        }

        function onComponentsHeaderClick(evt) {
            const $header = $(evt.currentTarget);

            $header.parent().toggleClass('collapsed');
        }

        function onChangeInputValue(evt) {
            const $input = $(evt.currentTarget);
            const key = $input.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];
            let value = setting.Type === global.Model.Enums.ComponentRepresentation.Boolean ?
                $input.is(':checked') :
                $input.val();

            if (setting.SubType === 'number') {
                value = parseFloat(value);
            }

            Tools.SetValueByPath(key, this, value, true);

            this.RenderOnPage();

            if (this.Marginal && (this.Marginal.Settings.OnComponentEdit instanceof Function)) {
                this.Marginal.Settings.OnComponentEdit(this);
            }
        }

        function onFileSelectionClick(evt) {
            const $input = $(evt.currentTarget);
            const key = $input.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];

            if (!setting) {
                return;
            }

            ChangeMode.FilePicker.Show({
                dataSourceFolders: changemode.Folders,
                dataSourceRootFolder: changemode.RootFolder,
                dataSourceFiles: changemode.Files,
                onPick: (file) => onPickFile.call(this, file, key),
                filterMimeTypes: setting.SubType === 'image' ? ['image'] : null
            });
        }

        function onPickFile(file, key) {
            if (!key) {
                return;
            }

            Tools.SetValueByPath(key, this, file.OID);

            const context = getPlacementArea.call(this);
            const $settings = $(context.Settings.SettingsContainerSelector);
            const $setting = $settings.find(`.setting[data-key="${key}"]`);

            $setting
                .find('.selected-file')
                .toggleClass('hide', !file)
                .html(Tools.escapeHtml(file.Title));

            this.RenderOnPage();

            if (this.Marginal && (this.Marginal.Settings.OnComponentEdit instanceof Function)) {
                this.Marginal.Settings.OnComponentEdit(this);
            }
        }

        function onMultiButtonSelectionButtonClick(evt) {
            const $button = $(evt.currentTarget);
            const key = $button.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];

            const subKey = $button.data('key');
            let propKey = key;

            if (!!subKey) {
                propKey += `.${subKey}`;
            }

            if (setting.AllowMultiSelection) {
                $button.toggleClass('active');
            } else {
                $button
                    .addClass('active')
                    .siblings('.active')
                    .removeClass('active');
            }

            const value = setting.AllowMultiSelection ?
                $button.hasClass('active') :
                $button.data('value');

            Tools.SetValueByPath(propKey, this, value, true);

            this.RenderOnPage();

            if (this.Marginal && (this.Marginal.Settings.OnComponentEdit instanceof Function)) {
                this.Marginal.Settings.OnComponentEdit(this);
            }
        }

        function onSettingsButtonClick(evt) {
            const $button = $(evt.currentTarget);
            const key = $button.closest('.setting').data('key');

            if (!key) {
                return;
            }

            const setting = this.SettingsMap[key];

            if (!setting) {
                return;
            }

            if (!(setting.OnClick instanceof Function)) {
                return;
            }

            setting.OnClick();
        }

        /**
         * @param {string} key
         * @param {string|number} value
         * @return {this}
         * @constructor
         */
        BaseComponent.prototype.UpdateTextInputSetting = function (key, value) {
            if (!this.IsActive) {
                return this;
            }

            if (!key) {
                return this;
            }

            const context = getPlacementArea.call(this);
            const $settings = $(context.Settings.SettingsContainerSelector);
            const $input = $settings.find(`.setting[data-key="${key}"] input`);

            if (!$input.length) {
                return this;
            }

            $input.val(value);

            return this;
        };

        /**
         * @param {Page} page
         * @return {this}
         */
        BaseComponent.prototype.SetPage = function (page) {
            if (!(page instanceof global.Model.Page)) {
                return this;
            }

            this.Page = page;

            return this;
        };

        /**
         * @param {Marginal} marginal
         * @return {this}
         */
        BaseComponent.prototype.SetMarginal = function (marginal) {
            if (!(marginal instanceof global.Model.Marginal)) {
                return this;
            }

            this.Marginal = marginal;

            return this;
        };

        /**
         * @param {$} $node
         * @return {this}
         */
        BaseComponent.prototype.SetNode = function ($node) {
            if (!($node instanceof $)) {
                return this;
            }

            this.$node = $node;

            return this;
        };

        /**
         * @param {number} relativeX
         * @param {number} relativeY
         * @return {this}
         */
        BaseComponent.prototype.SetCoordinates = function (relativeX, relativeY) {
            if (!this.Position) {
                console.warn(`Component position missing (${this.OID})`);
                return this;
            }

            this.Position.Coordinates.X = relativeX;
            this.Position.Coordinates.Y = relativeY;

            this
                .UpdateTextInputSetting('Position.Coordinates.X', relativeX)
                .UpdateTextInputSetting('Position.Coordinates.Y', relativeY);

            return this;
        };

        /**
         * @param {number} height
         * @param {number} width
         * @return {this}
         */
        BaseComponent.prototype.SetDimensions = function (height, width) {
            if (!this.Settings) {
                console.warn(`Component settings missing (${this.OID})`);
                return this;
            }

            this.Height = height;
            this.Width = width;

            this
                .UpdateTextInputSetting('Height', height)
                .UpdateTextInputSetting('Width', width);

            return this;
        };

        BaseComponent.prototype.OnComponentClick = function (evt) {
            evt.stopPropagation();

            if (this.IsActive) {
                return;
            }

            this.IsActive = true;

            if (this.Marginal) {
                $(`${this.Marginal.PageSelector} .component[data-identifier="${this.OID}"]`)
                    .toArray()
                    .forEach(n => $(n).addClass('active'));
            } else {
                this.$node.addClass('active');
            }

            this.RenderSettings();

            const context = getPlacementArea.call(this);
            const clickAction = context.Settings.OnComponentClick;

            if (clickAction instanceof Function) {
                clickAction.call(context, this);
            }
        };

        BaseComponent.prototype.UnSelect = function () {
            this.IsActive = false;

            if (this.Marginal) {
                $(`${this.Marginal.PageSelector} .component[data-identifier="${this.OID}"]`)
                    .toArray()
                    .forEach(n => $(n).removeClass('active'));
            } else {
                this.$node.removeClass('active');
            }
        };

        BaseComponent.prototype.Remove = function () {
            this.IsActive = false;

            if (this.Marginal) {
                $(`${this.Marginal.PageSelector} .component[data-identifier="${this.OID}"]`)
                    .toArray()
                    .forEach(n => n.remove());
            } else {
                this.$node.remove();
            }

            const context = getPlacementArea.call(this);
            const removeAction = context.Settings.OnComponentRemove;

            if (removeAction instanceof Function) {
                removeAction.call(context, this);
            }
        };

        return BaseComponent;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));