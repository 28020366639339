/**
 * @require ../_base/page.js
 * @require ../_base/page-tab.js
 */
(function (global) {
    var ClientSettingsView = function () {
        var availableTabs = getAvailableTabs.call(this);

        global.Base.Page.call(this, availableTabs);
    };

    ClientSettingsView.prototype = Object.create(global.Base.Page.prototype, {
        constructor: ClientSettingsView
    });

    ClientSettingsView.prototype.SaveEntity = function () {
        return putClient(changemode.Client);
    };

    function getAvailableTabs() {
        return [
            new global.Base.PageTab(
                'properties', getAvailableProperties.call(this)
            )
        ];
    }

    function getAvailableProperties() {
        return [
            new global.Base.Properties.PropertyGroup({
                ID: 'client-settings-qr-code',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.serviceQRCode'),
                        Description: i18next.t('changeMode.panels.properties.serviceQRCodeDescription'),
                        ID: 'service-qr-code',
                        Buttons: [{
                            ID: 'show-service-qr-code',
                            Caption: i18next.t('changeMode.panels.properties.show'),
                            OnClick: $.proxy(showServiceQrCode, this)
                        }]
                    })
                ]
            }),
            new global.Base.Properties.PropertyGroup({
                ID: 'client-settings-time',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.NumberProperty({
                        Caption: i18next.t('changeMode.panels.properties.timeForReminder'),
                        Description: i18next.t('changeMode.panels.properties.timeForReminderDescription'),
                        ID: 'time-for-reminder',
                        MappedProperty: 'TicketWarningOffset',
                        ConversionFactor: 60,
                        IsRequired: true
                    }),
                    new global.Base.Properties.NumberProperty({
                        Caption: i18next.t('changeMode.panels.properties.expirationTime'),
                        Description: i18next.t('changeMode.panels.properties.expirationTimeDescription'),
                        ID: 'expiration-time',
                        MappedProperty: 'TicketDeadlineOffset',
                        ConversionFactor: 60,
                        IsRequired: true
                    })
                ]
            }),
            new global.Base.Properties.PropertyGroup({
                ID: 'client-settings-properties',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.Label({
                        Caption: i18next.t('changeMode.panels.properties.issues.defaultStatus.caption'),
                        Description: i18next.t('changeMode.panels.properties.issues.defaultStatus.description')
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.defaultTaskStatus'),
                        ID: 'default-task-status',
                        MappedProperty: 'TicketOpened',
                        Buttons: [{
                            ID: 'select-default-task-status',
                            Caption: i18next.t('changeMode.panels.properties.selectStatus'),
                            OnClick: $.proxy(onBtnDefaultTaskStatusClick, this),
                            CaptionFormatter: $.proxy(formatPropertyButtonCaption, this)
                        }]
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.defaultFormStatus'),
                        ID: 'default-form-status',
                        MappedProperty: 'FormOpened',
                        Buttons: [{
                            ID: 'select-default-form-status',
                            Caption: i18next.t('changeMode.panels.properties.selectStatus'),
                            OnClick: $.proxy(onBtnDefaultFormStatusClick, this),
                            CaptionFormatter: $.proxy(formatPropertyButtonCaption, this)
                        }]
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.defaultInspectionStatus'),
                        ID: 'default-inspection-status',
                        MappedProperty: 'InspectionOpened',
                        Buttons: [{
                            ID: 'select-default-inspection-status',
                            Caption: i18next.t('changeMode.panels.properties.selectStatus'),
                            OnClick: $.proxy(onBtnDefaultInspectionStatusClick, this),
                            CaptionFormatter: $.proxy(formatPropertyButtonCaption, this)
                        }]
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.defaultDisturbanceStatus'),
                        ID: 'default-disturbance-status',
                        MappedProperty: 'DisturbanceOpened',
                        Buttons: [{
                            ID: 'select-default-disturbance-status',
                            Caption: i18next.t('changeMode.panels.properties.selectStatus'),
                            OnClick: $.proxy(onBtnDefaultDisturbanceStatusClick, this),
                            CaptionFormatter: $.proxy(formatPropertyButtonCaption, this)
                        }]
                    }),
                    new global.Base.Properties.Label({
                        Caption: i18next.t('changeMode.panels.properties.issues.statusAfterProcessing.caption')
                    }),
                    new global.Base.Properties.ResettableButtonProperty({
                        Caption: i18next.t('changeMode.panels.properties.defaultSyncBackFromExternalProcessingStatus'),
                        Description: i18next.t('changeMode.panels.properties.defaultSyncBackFromExternalProcessingStatusDescription'),
                        ID: 'status-after-external-processing',
                        MappedProperty: 'StatusAfterExternalProcessing',
                        OnSelectClick: $.proxy(onBtnSelectStatusAfterExternalProcessingClick, this),
                        OnResetClick: $.proxy(onResetSelectStatusAfterExternalProcessing, this),
                        ValueFormatter: $.proxy(formatPropertyButtonCaption, this)
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.taskCompletedStatus'),
                        ID: 'task-completed-status',
                        MappedProperty: 'TicketCompleted',
                        Buttons: [{
                            ID: 'select-task-completed-status',
                            Caption: i18next.t('changeMode.panels.properties.selectStatus'),
                            OnClick: $.proxy(onBtnTaskCompletedStatusClick, this),
                            CaptionFormatter: $.proxy(formatPropertyButtonCaption, this)
                        }]
                    }),
                    new global.Base.Properties.Label({
                        Caption: i18next.t('changeMode.panels.properties.issues.misc.caption')
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.defaultPriority'),
                        ID: 'ticket-opened-priority',
                        MappedProperty: 'TicketOpenedPriority',
                        Buttons: [{
                            ID: 'select-ticket-opened-priority',
                            Caption: i18next.t('changeMode.panels.properties.selectStatus'),
                            OnClick: $.proxy(onBtnDefaultTaskPriorityClick, this),
                            CaptionFormatter: $.proxy(formatPropertyButtonCaption, this)
                        }]
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.defaultCategory'),
                        Description: i18next.t('changeMode.panels.properties.defaultCategoryDescription'),
                        ID: 'default-deviation-category',
                        MappedProperty: 'ExpiredResubitemCat',
                        Buttons: [{
                            ID: 'select-default-deviation-category',
                            Caption: i18next.t('changeMode.panels.properties.selectValueCategory'),
                            OnClick: $.proxy(onBtnDefaultDeviationCategoryClick, this),
                            CaptionFormatter: $.proxy(formatPropertyButtonCaption, this)
                        }]
                    }),
                ]
            }),
            new global.Base.Properties.PropertyGroup({
                ID: 'client-settings-notifications',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.NumberProperty({
                        Caption: i18next.t('changeMode.panels.properties.overdueNotificationInterval'),
                        Description: i18next.t('changeMode.panels.properties.overdueNotificationIntervalDescription'),
                        ID: 'minimum-overdue-notification-interval',
                        MappedProperty: 'OverdueWaitingTime',
                        IsRequired: true,
                        Minimum: 5
                    }),
                    new global.Base.Properties.TimeProperty({
                        Caption: i18next.t('changeMode.panels.properties.dailyReportTime'),
                        ID: 'daily-report-time',
                        MappedProperty: 'DailyReportTime'
                    })
                ]
            }),
            new global.Base.Properties.PropertyGroup({
                ID: 'client-settings-print-options',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.defaultPrintOptions'),
                        Description: i18next.t('changeMode.panels.properties.defaultPrintOptionsDescription'),
                        ID: 'default-print-options',
                        MappedProperty: 'DefaultPrintOptions',
                        Buttons: [{
                            ID: 'select-default-print-options',
                            Caption: i18next.t('changeMode.panels.properties.defaultPrintOptionsButton'),
                            OnClick: $.proxy(onBtnDefaultPrintOptionsButtonClick, this)
                        }]
                    }),
                    new global.Base.Properties.CheckboxProperty({
                        Caption: i18next.t('changeMode.panels.properties.showPdfPreview'),
                        Description: i18next.t('changeMode.panels.properties.showPdfPreviewDescription'),
                        ID: 'show-pdf-preview',
                        MappedProperty: 'EnablePreviewChangemode'
                    })
                ]
            }),
            new global.Base.Properties.PropertyGroup({
                ID: 'client-settings-min-app-version',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.ResettableButtonProperty({
                        Caption: i18next.t('changeMode.panels.properties.minAppVersion.android'),
                        ID: 'min-app-version-android',
                        MappedProperty: 'MinAppVersionAndroid',
                        OnSelectClick: $.proxy(onBtnSelectMinimumAndroidAppVersionClick, this),
                        OnResetClick: $.proxy(onResetMinimumAndroidAppVersion, this),
                        ValueFormatter: $.proxy(formatMinimumAppVersionValue, this)
                    }),
                    new global.Base.Properties.ResettableButtonProperty({
                        Caption: i18next.t('changeMode.panels.properties.minAppVersion.iOS'),
                        ID: 'min-app-version-ios',
                        MappedProperty: 'MinAppVersionIOS',
                        OnSelectClick: $.proxy(onBtnSelectMinimumIOSAppVersionClick, this),
                        OnResetClick: $.proxy(onResetMinimumIOSAppVersion, this),
                        CaptionFormatter: $.proxy(formatMinimumAppVersionValue, this)
                    })
                ]
            }),
            new global.Base.Properties.PropertyGroup({
                ID: 'client-settings-sync-options',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.CheckboxProperty({
                        Caption: i18next.t('changeMode.panels.properties.syncOpenSurveys'),
                        Description: i18next.t('changeMode.panels.properties.syncOpenSurveysDescription'),
                        ID: 'sync-open-surveys',
                        MappedProperty: 'SyncOpenSurveys'
                    }),
                    new global.Base.Properties.CheckboxProperty({
                        Caption: i18next.t('changeMode.panels.properties.syncOpenInvestigations'),
                        Description: i18next.t('changeMode.panels.properties.syncOpenInvestigationsDescription'),
                        ID: 'sync-open-investigations',
                        MappedProperty: 'SyncOpenInvestigations'
                    })
                ]
            }),
            new global.Base.Properties.PropertyGroup({
                ID: 'client-settings-misc',
                Type: global.PropertyTypes.Group,
                Properties: [
                    new global.Base.Properties.CheckboxProperty({
                        Caption: i18next.t('changeMode.panels.properties.inheritAssignedTeams'),
                        Description: i18next.t('changeMode.panels.properties.inheritAssignedTeamsDescription'),
                        ID: 'inherit-assigned-teams',
                        MappedProperty: 'TakeOverAssignedTeams'
                    }),
                    new global.Base.Properties.CheckboxProperty({
                        Caption: i18next.t('changeMode.panels.properties.askForFormCoding'),
                        Description: i18next.t('changeMode.panels.properties.askForFormCodingDescription'),
                        ID: 'ask-for-form-coding',
                        MappedProperty: 'AskForFormCoding'
                    }),
                    new global.Base.Properties.CheckboxProperty({
                        Caption: i18next.t('changeMode.panels.properties.disableActionAutoDescription.title'),
                        Description: i18next.t('changeMode.panels.properties.disableActionAutoDescription.description'),
                        ID: 'disable-action-auto-description',
                        MappedProperty: 'DisableActionAutoDescription'
                    }),
                    new global.Base.Properties.ButtonRowProperty({
                        Caption: i18next.t('changeMode.panels.properties.estimatedEffortEditor.title'),
                        Description: i18next.t('changeMode.panels.properties.estimatedEffortEditor.description'),
                        ID: 'estimated-effort-settings',
                        MappedProperty: 'EstimatedEffortSettings',
                        Buttons: [{
                            ID: 'defined-estimated-effort-presets',
                            Caption: i18next.t('changeMode.panels.properties.show'),
                            OnClick: $.proxy(onDefineEstimatedEffortPresets, this)
                        }]
                    })
                ]
            }),
        ];
    }

    function showServiceQrCode() {
        Tools.QRCode.Show(
            Client.Settings.ServiceURI,
            'awenko:360 - ' + i18next.t('changeMode.panels.properties.serviceAddress')
        );
    }

    function formatPropertyButtonCaption(propertyIdentifier) {
        if (!propertyIdentifier) {
            return null;
        }

        var property = changemode.Properties[propertyIdentifier];

        if (!property || property.ModificationType === Enums.ModificationType.Deleted) {
            return null;
        }

        return [
            '<span class="color" style="background-color:', property.Color, '"></span>',
            property.Title
        ].join('');
    }

    function onBtnDefaultTaskPriorityClick(evt) {
        var $btn = $(evt.currentTarget);
        var viewProperty = this.CurrentTab.PropertyMap['ticket-opened-priority'];

        propertyClickListener($btn, viewProperty, Enums.propertyType.Priority);
    }

    function onBtnDefaultTaskStatusClick(evt) {
        var $btn = $(evt.currentTarget);
        var viewProperty = this.CurrentTab.PropertyMap['default-task-status'];

        propertyClickListener($btn, viewProperty, Enums.propertyType.Status, function (status) {
            return !status.SetStateOnlyIfAllRequiredCollected;
        });
    }

    function onBtnDefaultFormStatusClick(evt) {
        var $btn = $(evt.currentTarget);
        var viewProperty = this.CurrentTab.PropertyMap['default-form-status'];

        propertyClickListener($btn, viewProperty, Enums.propertyType.Status, function (status) {
            return !status.SetStateOnlyIfAllRequiredCollected;
        });
    }

    function onBtnDefaultInspectionStatusClick(evt) {
        var $btn = $(evt.currentTarget);
        var viewProperty = this.CurrentTab.PropertyMap['default-inspection-status'];

        propertyClickListener($btn, viewProperty, Enums.propertyType.Status, function (status) {
            return !status.SetStateOnlyIfAllRequiredCollected;
        });
    }

    function onBtnDefaultDisturbanceStatusClick(evt) {
        var $btn = $(evt.currentTarget);
        var viewProperty = this.CurrentTab.PropertyMap['default-disturbance-status'];

        propertyClickListener($btn, viewProperty, Enums.propertyType.Status);
    }

    function onBtnTaskCompletedStatusClick(evt) {
        var $btn = $(evt.currentTarget);
        var viewProperty = this.CurrentTab.PropertyMap['task-completed-status'];

        propertyClickListener($btn, viewProperty, Enums.propertyType.Status);
    }

    function onBtnSelectStatusAfterExternalProcessingClick(evt) {
        var $btn = $(evt.currentTarget);
        var viewProperty = this.CurrentTab.PropertyMap['status-after-external-processing'];

        propertyClickListener($btn, viewProperty, Enums.propertyType.Status);
    }

    function onResetSelectStatusAfterExternalProcessing(evt) {
        var viewProperty = this.CurrentTab.PropertyMap['status-after-external-processing'];

        viewProperty.SetValue(null);
        changemode.CurrentPage.CurrentTab.MarkAsModified();
    }

    function onBtnDefaultDeviationCategoryClick(evt) {
        var $btn = $(evt.currentTarget);
        var viewProperty = this.CurrentTab.PropertyMap['default-deviation-category'];

        propertyClickListener($btn, viewProperty, Enums.propertyType.ValueCategory);
    }

    function propertyClickListener($btn, viewProperty, type, isPropertySelectable) {
        var propertyOID = viewProperty.GetValue();

        isPropertySelectable = isPropertySelectable || function () { return true; };

        var options = {
            title: i18next.t('explorer.issues.teamFilter.title'),
            selectMultiple: false,
            onSelect: function (propertyOID) {
                var caption = formatPropertyButtonCaption(propertyOID);

                $btn.html(caption);

                viewProperty.SetValue(propertyOID);
                changemode.CurrentPage.CurrentTab.MarkAsModified();
            },
            treeOptions: {
                schema: function (property) {
                    var isSelectable = isPropertySelectable(property);
                    var classes = isSelectable ?
                        (propertyOID !== property.OID ? '' : 'default-fade-out') :
                        'jquery-tree-node-disabled';

                    return {
                        id: property.OID,
                        children: property.Children,
                        text: property.Title,
                        color: property.Color,
                        selectable: isSelectable,
                        classes: classes
                    };
                },
                opened: true,
                selectable: true,
                selected: propertyOID,
                filter: function (property) { return property.ModificationType !== Enums.ModificationType.Deleted; }
            }
        };

        var entities;

        switch (type) {
            case Enums.propertyType.Status:
                entities = changemode.Status;
                options.title = i18next.t('changeMode.panels.properties.selectStatus');
                break;
            case Enums.propertyType.Priority:
                entities = changemode.Priorities;
                options.title = i18next.t('changeMode.panels.properties.selectPriority');
                break;
            case Enums.propertyType.ValueCategory:
                entities = changemode.Categories;
                options.title = i18next.t('changeMode.panels.properties.selectValueCategory');
                break;
        }

        TreePicker.Show(entities, options);
    }

    function onBtnDefaultPrintOptionsButtonClick() {
        var $printOptionsPicker = $('#printOptionsPicker');
        var $overflow = $('#overflow');
        var viewProperty = this.CurrentTab.PropertyMap['default-print-options'];

        var settings = {
            Wrapper: $printOptionsPicker.find('.printOptionsWrapper'),
            ApplyButton: $printOptionsPicker.find('.btn-apply'),
            AbortButton: $printOptionsPicker.find('.top .close, .btn-abort'),
            Searchbox: $printOptionsPicker.find('.searchbox'),
            PrintType: 'Issue',
            OnSave: function(anyChanged) {
                if (!anyChanged) {
                    return;
                }

                var value = JSON.stringify(PrintOptionsPicker.GetPrintOptions());

                viewProperty.SetValue(value);

                changemode.CurrentPage.CurrentTab.MarkAsModified();
            },
            OnHide: function() {
                $printOptionsPicker.addClass('hide');
                $overflow.addClass('hide');
            }
        };

        var printOptions = viewProperty.GetValue();

        if (printOptions) {
            while (typeof printOptions === 'string') {
                printOptions = JSON.parse(printOptions);
            }

            settings.PrintOptions = printOptions;
        }

        $('#printOptionsPicker .top .title').text(i18next.t('printOptionsPicker.title'));
        $('#printOptionsPicker .bottom .btn-abort').text(i18next.t('printOptionsPicker.abort'));
        $('#printOptionsPicker .bottom .btn-apply').text(i18next.t('printOptionsPicker.apply'));

        PrintOptionsPicker.Create(settings);

        $overflow.removeClass('hide');
        $printOptionsPicker.removeClass('hide');
    }

    function formatMinimumAppVersionValue(value) {
        return value === 'none' ?
            i18next.t('misc.none') :
            value;
    }

    function onBtnSelectMinimumAndroidAppVersionClick(evt) {
        var $btn = $(evt.currentTarget);
        var availableVersions = AppVersions.filter(function (version) {
            return version.Android;
        });

        var popupHeading = i18next.t('changeMode.panels.properties.minAppVersion.android');
        var viewProperty = this.CurrentTab.PropertyMap['min-app-version-android'];

        onOpenAppVersionSelection($btn, availableVersions, popupHeading, viewProperty);
    }

    function onBtnSelectMinimumIOSAppVersionClick(evt) {
        var $btn = $(evt.currentTarget);
        var availableVersions = AppVersions.filter(function (version) {
            return version.IOS;
        });

        var popupHeading = i18next.t('changeMode.panels.properties.minAppVersion.iOS');
        var viewProperty = this.CurrentTab.PropertyMap['min-app-version-ios'];

        onOpenAppVersionSelection($btn, availableVersions, popupHeading, viewProperty);
    }

    function onOpenAppVersionSelection($btn, availableVersions, popupHeading, viewProperty) {
        var options = {
            title: popupHeading,
            selectMultiple: false,
            onSelect: function (value) {
                onSelectMinimumAppVersion($btn, viewProperty, value);
            },
            treeOptions: {
                schema: function (property) {
                    return {
                        id: property.Version,
                        text: property.Version
                    };
                },
                opened: true,
                selectable: true,
                selected: viewProperty.GetValue()
            }
        };

        TreePicker.Show(availableVersions, options);
    }

    function onSelectMinimumAppVersion($btn, viewProperty, version) {
        $btn.html(version);
        viewProperty.SetValue(version);
        changemode.CurrentPage.CurrentTab.MarkAsModified();
    }

    function onResetMinimumAndroidAppVersion(evt) {
        var viewProperty = this.CurrentTab.PropertyMap['min-app-version-android'];
        var $btn = $(evt.currentTarget);
        var $selectBtn = $btn.siblings('.select');

        onSelectMinimumAppVersion($selectBtn, viewProperty, null);
    }

    function onResetMinimumIOSAppVersion(evt) {
        var viewProperty = this.CurrentTab.PropertyMap['min-app-version-ios'];
        var $btn = $(evt.currentTarget);
        var $selectBtn = $btn.siblings('.select');

        onSelectMinimumAppVersion($selectBtn, viewProperty, null);
    }

    function onDefineEstimatedEffortPresets() {
        var viewProperty = this.CurrentTab.PropertyMap['estimated-effort-settings'];
        var presets = viewProperty.GetValue();

        if (typeof presets === 'string') {
            presets = JSON.parse(presets);
        }

        var settings = {
            onApply: function () {
                var value = JSON.stringify(Tools.EstimatedEffortEditor.GetCustomEstimatedEffort());

                viewProperty.SetValue(value);
                Tools.EstimatedEffortEditor.Hide();

                changemode.CurrentPage.CurrentTab.MarkAsModified();
            },
            estimatedEffortSettings: presets || null
        };

        Tools.EstimatedEffortEditor.Show(settings);
    }

    if (!global.System) {
        global.System = {};
    }

    global.System.ClientSettings = ClientSettingsView;
})(Modifications.Pages || (Modifications.Pages = {}));