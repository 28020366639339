/**
 * @require ./pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    global.Model.ComponentFactory = (function () {
        function ComponentFactory(checkpointMap) {
            this.CheckpointMap = checkpointMap;
        }

        /**
         * @param {object} rawComponent
         * @param {string} renderArea
         * @return {BaseComponent|null}
         */
        ComponentFactory.prototype.CreateComponentFromDefinition = function (rawComponent, renderArea) {
            if (rawComponent == null) {
                return null;
            }

            if (!renderArea) {
                return null;
            }

            /**
             * @type {BaseComponent}
             */
            let component;

            switch (rawComponent.Type) {
                case global.Model.Enums.ComponentType.Checkpoint:
                    const checkpoint = this.CheckpointMap[rawComponent.ParameterOID];

                    if (!checkpoint) {
                        return null;
                    }

                    component = this.CreateCheckpointComponent(checkpoint);
                    component.Textflow = rawComponent.Textflow;
                    component.ContentType = rawComponent.ContentType;

                    if (rawComponent.SuppressUnit != null) {
                        component.SuppressUnit = rawComponent.SuppressUnit;
                    }

                    if (rawComponent.ShowAllListboxOptions != null) {
                        component.ShowAllListboxOptions = rawComponent.ShowAllListboxOptions;
                    }
                    break;
                case global.Model.Enums.ComponentType.Image:
                    component = new global.Model.CataloguePictureComponent(null, null, {});
                    component.FileOID = rawComponent.FileOID;
                    break;
                case global.Model.Enums.ComponentType.Geometry:
                    component = rawComponent.Points.length === 4 ?
                        new global.Model.RectangleComponent(null, null, {}) :
                        null;

                    if (!component) {
                        return null;
                    }

                    component.Points = rawComponent.Points;

                    rawComponent.Width = rawComponent.Points[1].X;
                    rawComponent.Height = rawComponent.Points[2].Y;
                    break;
                case global.Model.Enums.ComponentType.Text:
                    component = new global.Model.TextComponent(null, null, {});
                    component.Textflow = rawComponent.Textflow;
                    break;
                default:
                    component = new global.Model.GenericInformationComponent(null, null, {});
                    component.Textflow = rawComponent.Textflow;
                    break;
            }

            if (!component) {
                return null;
            }

            component.Layout = rawComponent.Layout;
            component.Position = rawComponent.Position;
            component.Height = rawComponent.Height;
            component.Width = rawComponent.Width;
            component.OID = rawComponent.OID;
            component.RenderArea = renderArea;

            delete component.Title;
            delete component.Description;
            delete component.Icon;
            delete component.IsTemplate;

            return component;
        }

        /**
         * @param checkpoint
         * @return {CheckpointBaseComponent|null}
         */
        ComponentFactory.prototype.CreateCheckpointComponent = function (checkpoint) {
            if (!checkpoint) {
                return null;
            }

            switch (checkpoint.Type) {
                case Enums.elementType.Checkbox:
                    return new global.Model.CheckboxCpComponent(checkpoint, {});
                case Enums.elementType.Number:
                    return new global.Model.NumberCpComponent(checkpoint, {});
                case Enums.elementType.Line:
                    return new global.Model.LineCpComponent(checkpoint, {});
                case Enums.elementType.Memo:
                    return new global.Model.MemoCpComponent(checkpoint, {});
                case Enums.elementType.Date:
                    return new global.Model.DateCpComponent(checkpoint, {});
                case Enums.elementType.Time:
                    return new global.Model.TimeCpComponent(checkpoint, {});
                case Enums.elementType.Photo:
                    return new global.Model.PhotoCpComponent(checkpoint, {});
                case Enums.elementType.Scancode:
                    return new global.Model.ScanCodeCpComponent(checkpoint, {});
                case Enums.elementType.LocationCode:
                    return new global.Model.LocationCodeCpComponent(checkpoint, {});
                case Enums.elementType.ListBox:
                    return new global.Model.ListBoxCpComponent(checkpoint, {});
                case Enums.elementType.MultiListBox:
                    return new global.Model.MultiListBoxCpComponent(checkpoint, {});
                case Enums.elementType.Info:
                    return new global.Model.InfoCpComponent(checkpoint, {});
                case Enums.elementType.Signature:
                    return new global.Model.SignatureCpComponent(checkpoint, {});
                case Enums.elementType.UsersAndTeams:
                    return new global.Model.UserAndTeamsCpComponent(checkpoint, {});
                case Enums.elementType.IndividualData:
                    return new global.Model.IndividualDataCpComponent(checkpoint, {});
                case Enums.elementType.PhoneNumber:
                    return new global.Model.PhoneNumberCpComponent(checkpoint, {});
                case Enums.elementType.EMailAddresses:
                    return new global.Model.EMailAddressesCpComponent(checkpoint, {});
                case Enums.elementType.Files:
                    return new global.Model.FilesCpComponent(checkpoint, {});
            }

            return null;
        }

        return ComponentFactory;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));