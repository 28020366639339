/**
 * @require ./pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @augments {BaseComponent}
     * @typedef {UnknownComponent}
     */
    global.Model.UnknownComponent = (function () {
        /**
         * @param {string} title
         * @param {string} description
         * @param {ComponentSettings} settings
         * @constructor
         */
        function UnknownComponent(title, description, settings) {
            global.Model.BaseComponent.call(this, 'unknown', title, description, null, settings);
        }

        UnknownComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        UnknownComponent.prototype.constructor = UnknownComponent;

        UnknownComponent.prototype.CreateComponentContent = function () {
            return 'unknown component';
        };

        return UnknownComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef {TextComponent}
     */
    global.Model.TextComponent = (function () {
        /**
         * @param {string} title
         * @param {string} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function TextComponent(title, description, settings) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.textLabel.label'),
                            Key: 'Textflow.Text',
                            Type: global.Model.Enums.ComponentRepresentation.MultiLineInput,
                            SubType: 'text'
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.label'),
                            Key: 'Textflow',
                            AllowMultiSelection: true,
                            Type: global.Model.Enums.ComponentRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.bold'),
                                    Icon: './img/bold-solid.svg',
                                    Key: 'Bold'
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.italic'),
                                    Icon: './img/italic-solid.svg',
                                    Key: 'Italic'
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.style.underline'),
                                    Icon: './img/underline-solid.svg',
                                    Key: 'Underline'
                                }
                            ]
                        }
                    ]
                },
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.layout.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.label'),
                            Key: 'Layout.HorizontalAlignment',
                            Type: global.Model.Enums.ComponentRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.left'),
                                    Icon: './img/align-left-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Left
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.center'),
                                    Icon: './img/align-center-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.right'),
                                    Icon: './img/align-right-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Right
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.justify'),
                                    Icon: './img/align-justify-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Justify
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.label'),
                            Key: 'Layout.VerticalAlignment',
                            Type: global.Model.Enums.ComponentRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.top'),
                                    Icon: './img/vertical-align-top.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Top
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.center'),
                                    Icon: './img/vertical-align-center.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.bottom'),
                                    Icon: './img/vertical-align-bottom.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Bottom
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.rotation.label'),
                            Key: 'Layout.Rotation',
                            Type: global.Model.Enums.ComponentRepresentation.Input,
                            SubType: 'number',
                            Min: -360,
                            Max: 360,
                            Default: 0
                        },
                    ]
                }
            ];

            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Text,
                title,
                description,
                'font-solid.svg',
                settings
            );
        }

        TextComponent.prototype = Object.create(global.Model.BaseComponent.prototype);

        TextComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();
            const additionalSettings = {
                Textflow: {
                    Text: this.Title
                },
                Layout: {
                    HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                    VerticalAlignment: global.Model.Enums.VerticalAlignment.Top
                }
            };

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY,
                additionalSettings
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        /**
         * @return {string}
         */
        TextComponent.prototype.CreateComponentContent = function () {
            const markup = ['<span'];
            const styles = [];

            if (!!this.Textflow.Color) {
                styles.push(`color="${this.Textflow.Color}"`);
            }

            if (typeof this.Layout.Rotation === 'number' && !isNaN(this.Layout.Rotation)) {
                styles.push(`transform:rotate(${this.Layout.Rotation}deg)`);
            }

            if (styles.length) {
                markup.push(` style="${styles.join(';')}"`);
            }

            markup.push(`>${DOMPurify.sanitize(this.Textflow.Text).replace(/\r?\n|\r/g, '<br />')}</span>`);

            return markup.join('');
        };

        TextComponent.prototype.constructor = TextComponent;

        return TextComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef {CataloguePictureComponent}
     */
    global.Model.CataloguePictureComponent = (function () {
        /**
         * @param {string} title
         * @param {string} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function CataloguePictureComponent(title, description, settings) {
            this.ComponentSettings = [
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.files.image'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.files.fileSelection.label'),
                            Key: 'FileOID',
                            Type: global.Model.Enums.ComponentRepresentation.FileSelection,
                            SubType: 'image'
                        }
                    ]
                },
                {
                    Label: i18next.t('changeMode.pdfDesigner.settings.layout.label'),
                    Settings: [
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.label'),
                            Key: 'Layout.HorizontalAlignment',
                            Type: global.Model.Enums.ComponentRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.left'),
                                    Icon: './img/align-left-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Left
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.center'),
                                    Icon: './img/align-center-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.horizontalAlignment.right'),
                                    Icon: './img/align-right-solid.svg',
                                    Value: global.Model.Enums.HorizontalAlignment.Right
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.label'),
                            Key: 'Layout.VerticalAlignment',
                            Type: global.Model.Enums.ComponentRepresentation.MultiButtonSelection,
                            Options: [
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.top'),
                                    Icon: './img/vertical-align-top.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Top
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.center'),
                                    Icon: './img/vertical-align-center.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Center
                                },
                                {
                                    Caption: i18next.t('changeMode.pdfDesigner.settings.textFlow.verticalAlignment.bottom'),
                                    Icon: './img/vertical-align-bottom.svg',
                                    IconStyle: 'width:100%;',
                                    Value: global.Model.Enums.VerticalAlignment.Bottom
                                }
                            ]
                        },
                        {
                            Label: i18next.t('changeMode.pdfDesigner.settings.layout.rotation.label'),
                            Key: 'Layout.Rotation',
                            Type: global.Model.Enums.ComponentRepresentation.Input,
                            SubType: 'number',
                            Min: -360,
                            Max: 360,
                            Default: 0
                        }
                    ]
                }
            ];

            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Image,
                title,
                description,
                'image-regular.svg',
                settings
            );

            this.DefaultDimensions = {
                Width: 30,
                Height: 22
            };
        }

        CataloguePictureComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        CataloguePictureComponent.prototype.constructor = CataloguePictureComponent;

        CataloguePictureComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();
            const additionalSettings = {
                Layout: {
                    HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Center,
                    VerticalAlignment: global.Model.Enums.VerticalAlignment.Center
                }
            };

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY,
                additionalSettings
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        CataloguePictureComponent.prototype.CreateComponentContent = function () {
            const file = !!this.FileOID ? changemode.Files[this.FileOID] : null;
            const filePath = file ? `${Config.BaseUri}images/s/${file.Filename}` : './img/image-regular.svg';

            return `<img src="${filePath}" style="max-height:100%;max-width:100%;" />`;
        };

        return CataloguePictureComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef {TableComponent}
     */
    global.Model.TableComponent = (function () {
        /**
         * @param {string} title
         * @param {string} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function TableComponent(title, description, settings) {
            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Table,
                title,
                description,
                'table-solid.svg',
                settings
            );
        }

        TableComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        TableComponent.prototype.constructor = TableComponent;

        TableComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        TableComponent.prototype.CreateComponentContent = function () {
            return 'Tabelle';
        };

        return TableComponent;
    })();

    /**
     * @augments {BaseComponent}
     * @typedef {TableComponent}
     */
    global.Model.RectangleComponent = (function () {
        /**
         * @param {string} title
         * @param {string} description
         * @param {ComponentSettings?} settings
         * @constructor
         */
        function RectangleComponent(title, description, settings) {
            global.Model.BaseComponent.call(
                this,
                global.Model.Enums.ComponentType.Geometry,
                title,
                description,
                'rectangle-empty.svg',
                settings
            );

            /**
             * @type {Point[]}
             */
            this.Points = [];

            this.Layout = {
                HorizontalAlignment: global.Model.Enums.HorizontalAlignment.Left,
                VerticalAlignment: global.Model.Enums.VerticalAlignment.Top,
                Rotation: 0,
                Fitmethod: global.Model.Enums.FitMethod.Auto
            };

            /**
             * @type {string}
             */
            this.StrokeColor = '#000000';

            this.ZIndex = 1;
        }

        RectangleComponent.prototype = Object.create(global.Model.BaseComponent.prototype);
        RectangleComponent.prototype.constructor = RectangleComponent;

        RectangleComponent.prototype.CreateDerivedInstance = function (relativeX, relativeY) {
            const $deferred = $.Deferred();

            global.Model.BaseComponent.prototype.CreateDerivedInstance.call(
                this,
                relativeX,
                relativeY
            ).then(component => $deferred.resolve(component));

            return $deferred.promise();
        };

        /**
         * @param {number} height
         * @param {number} width
         * @return {this}
         */
        RectangleComponent.prototype.SetDimensions = function (height, width) {
            global.Model.BaseComponent.prototype.SetDimensions.call(
                this,
                height,
                width
            );

            this.Points = [
                { X: 0, Y: 0 },
                { X: width, Y: 0 },
                { X: width, Y: height },
                { X: 0, Y: height }
            ];

            return this;
        };

        RectangleComponent.prototype.CreateComponentContent = function () {
            return 'Rechteck';
        };

        return RectangleComponent;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));