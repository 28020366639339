/**
 * @require ./pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @callback FnOnPageComponentClick
     * @param {BaseComponent}
     */

    /**
     * @typedef CommonSettings
     * @property {string} OID
     * @property {boolean} IsTemplate
     * @property {FnOnPageComponentClick} OnPageComponentClick
     * @property {string} Placeholder
     */

    /**
     * @typedef {ComponentSettings}
     */
    global.Model.ComponentSettings = (function () {
        /**
         * @param {CommonSettings} settings
         * @constructor
         */
        function ComponentSettings(settings) {
            /**
             * @type {boolean}
             */
            this.IsTemplate = !!settings.IsTemplate;

            /**
             * @type {string}
             */
            this.OID = settings.OID;

            /**
             * @type {Function}
             */
            this.OnPageComponentClick = settings.OnPageComponentClick;

            /**
             * @type {string|null}
             */
            this.Placeholder = this.IsTemplate ? settings.Placeholder : null;
        }

        ComponentSettings.prototype.constructor = ComponentSettings;

        return ComponentSettings;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));