/**
 * @require ./pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @readonly
     */
    global.Model.Enums = {
        PositionType: {
            Relative: 0,
            Absolute: 1
        },
        ComponentType: {
            Text: 0,
            Table: 1,
            Image: 2,
            Geometry: 3,
            Checkpoint: 4
        },
        ComponentRepresentation: {
            Dropdown: 'dropdown',
            FileSelection: 'file-selection',
            Info: "info",
            Input: 'input',
            MultiLineInput: 'multi-line-input',
            MultiButtonSelection: 'multi-button-selection',
            Button: 'button',
            Boolean: 'boolean'
        },
        HorizontalAlignment: {
            Left: 0,
            Right: 1,
            Center: 2,
            Justify: 3
        },
        VerticalAlignment: {
            Top: 0,
            Center: 1,
            Bottom: 2,
            Justify: 3
        },
        FitMethod: {
            Auto: 0,
            Clip: 1,
            Meet: 2,
            NoFit: 3
        },
        SectionModificationArea: {
            Footer: 'footer',
            Header: 'header',
            Page: 'page',
        },
        CheckpointContentType: {
            Value: 0,
            Title: 1,
            Description: 2,
            CreatorName: 3,
            EditorName: 4,
            CreationTimestamp: 5,
            ModificationTimestamp: 6
        },
        CheckpointTextPrefixes: {
            CreationTimestamp: 'CP_CreationTimestamp_',
            CreatorName: 'CP_CreatorName_',
            Description: 'CP_Description_',
            EditorName: 'CP_EditorName_',
            ModificationTimestamp: 'CP_ModificationTimestamp_',
            Title: 'CP_Title_',
            Value: 'CP_Value_'
        }
    };
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));