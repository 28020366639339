/**
 * @require ./pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @typedef Dimensions
     * @property {int} Width
     * @property {int} Height
     */

    /**
     * @typedef {PageSettings}
     */
    global.Model.PageSettings = (function () {
        /**
         * @param {string} baseSelector
         * @param {string} settingsContainerSelector
         * @param {Function} onPageClick
         * @param {Function} onComponentClick
         * @param {Function} onComponentRemove
         * @constructor
         */
        function PageSettings(baseSelector, settingsContainerSelector, onPageClick, onComponentClick, onComponentRemove) {
            /**
             * @type {string}
             */
            this.BaseSelector = baseSelector + ' ';

            /**
             * @type {string}
             */
            this.SettingsContainerSelector = this.BaseSelector + settingsContainerSelector;

            /**
             * @type {Function}
             */
            this.OnPageClick = onPageClick;

            /**
             * @type {Function}
             */
            this.OnComponentClick = onComponentClick;

            /**
             * @type {Function}
             */
            this.OnComponentRemove = onComponentRemove;
        }

        return PageSettings;
    })();

    /**
     * @typedef {Page}
     */
    global.Model.Page = (function () {
        /**
         * @param {PageSettings} settings
         * @param {number} pageNo
         * @param {BaseComponent[]|null} components
         * @constructor
         */
        function Page(settings, pageNo, components) {
            if (settings == null) {
                throw new Error('No settings for this page provided');
            }

            /**
             * @type {PageSettings}
             */
            this.Settings = settings;

            /**
             * @type {number}
             */
            this.PageNo = pageNo;

            /**
             * @type {Section}
             */
            this.Section = null;

            /**
             * @type {BaseComponent[]}
             */
            this.Content = components || [];

            /**
             * @type {Object.<string, BaseComponent>}
             */
            this.ComponentMap = {};

            this.Content.forEach(component => {
                this.ComponentMap[component.OID] = component;
                component.SetPage(this);
            });

            /**
             * @type {string}
             */
            this.PageSelector = `${this.Settings.BaseSelector} .page[data-page-no="${this.PageNo}"]`;
        }

        /**
         * @return {string}
         */
        Page.prototype.Render = function () {
            return `<div class="canvas page ${this.Section.Settings.Format}" data-page-no="${this.PageNo}"></div>`;
        };

        /**
         * @return {Page}
         */
        Page.prototype.BindEvents = function () {
            $(this.PageSelector).on('click', $.proxy(this.Settings.OnPageClick, this));

            return this;
        };

        /**
         * @return {Page}
         */
        Page.prototype.DetermineDimensions = function () {
            const $page = $(this.PageSelector);

            /**
             * @type {number}
             */
            this.Width = $page.width();

            /**
             * @type {number}
             */
            this.Height = $page.height();

            return this;
        };

        /**
         * @param {Section} section
         * @return {Page}
         */
        Page.prototype.SetSection = function (section) {
            if (!section) {
                return this;
            }

            this.Section = section;

            return this;
        };

        /**
         * @return {this}
         */
        Page.prototype.RenderHeader = function () {
            if (!this.Section || !this.Section.Header) {
                return this;
            }

            const height = (this.Section.Header.Height / 100) * this.Height;
            const markup = `<div class="page-header" style="height:${height}px"></div>`;
            const $page = $(this.PageSelector);

            $page.append(markup);

            return this;
        };

        /**
         * @return {Page}
         */
        Page.prototype.RenderMainArea = function () {
            const $page = $(this.PageSelector);

            let heightValue = this.Section.Header || this.Section.Footer ?
                'calc(100%' :
                '100%';

            if (this.Section.Header) {
                const headerHeight = (this.Section.Header.Height / 100) * this.Height;

                heightValue += ` - ${headerHeight}px`;
            }

            if (this.Section.Footer) {
                const footerHeight = (this.Section.Footer.Height / 100) * this.Height;

                heightValue += ` - ${footerHeight}px`;
            }

            if (this.Section.Header || this.Section.Footer) {
                heightValue += ')';
            }

            $page.append(`<div class="main-area" style="height:${heightValue};"></div>`);

            return this;
        };

        /**
         * @return {this}
         */
        Page.prototype.RenderFooter = function () {
            if (!this.Section || !this.Section.Footer) {
                return this;
            }

            const height = (this.Section.Footer.Height / 100) * this.Height;
            const markup = `<div class="page-footer" style="height:${height}px"></div>`;
            const $page = $(this.PageSelector);

            $page.append(markup);

            return this;
        };

        /**
         * @return {Page}
         */
        Page.prototype.PlaceComponents = function () {
            (this.Content || [])
                .forEach(component => component.RenderOnPage(this));

            return this;
        };

        /**
         * @param {BaseComponent} component
         */
        Page.prototype.AddComponent = function (component) {
            if (!component || component.IsTemplate) {
                return;
            }

            this.Content = this.Content || [];
            this.ComponentMap = this.ComponentMap || {};

            this.Content.push(component);
            this.ComponentMap[component.OID] = component;

            component.SetPage(this);

            return this;
        };

        /**
         * @param {string} identifier
         * @return {BaseComponent|null}
         */
        Page.prototype.GetComponent = function (identifier) {
            if (!identifier) {
                return null;
            }

            return this.ComponentMap[identifier];
        };

        /**
         * @param {string} identifier
         */
        Page.prototype.RemoveComponent = function (identifier) {
            if (!identifier) {
                return null;
            }

            const component = this.GetComponent(identifier);

            if (!component) {
                return this;
            }

            component.Remove();

            const idx = Tools.indexOf(this.Content, identifier, 'OID');

            if (idx === -1) {
                return this;
            }

            this.Content.splice(idx, 1);
            delete this.ComponentMap[identifier];

            return this;
        };

        /**
         * Versucht Komponenten, die sich außerhalb des Randbereichs befinden, neu zu positionieren.
         * @return {{FailedToPosition: number, OutOfPosition: number}}
         */
        Page.prototype.TryRealignComponents = function () {
            const headerHeight = this.Section.Header ? this.Section.Header.GetHeight() : 0;
            const footerHeight = this.Section.Footer ? this.Section.Footer.GetHeight() : 0;

            const componentsOutOfPosition = this.Content.filter(component => {
                const yPosition = component.Position.Coordinates.Y;

                return yPosition <= headerHeight || yPosition + component.Height >= 100 - footerHeight;
            });

            const result = {
                OutOfPosition: componentsOutOfPosition.length,
                FailedToPosition: 0
            };

            if (componentsOutOfPosition.length === 0) {
                return result;
            }

            for (const component of componentsOutOfPosition) {
                const yPosition = component.Position.Coordinates.Y;
                let newYPosition = 0;

                if (yPosition <= headerHeight) {
                    newYPosition = headerHeight + 1;
                } else if (yPosition + component.Height >= 100 - footerHeight) {
                    newYPosition = 100 - footerHeight - component.Height - 1;
                }

                if (newYPosition + component.Height >= 100 - footerHeight) {
                    result.FailedToPosition++;
                    continue;
                }

                component.SetCoordinates(component.Position.Coordinates.X, newYPosition);
            }

            return result;
        };

        /**
         * Gibt zurück, ob die Seite Komponenten besitzt
         * @return {boolean}
         * @constructor
         */
        Page.prototype.HasContent = function () {
            return (this.Content || []).length > 0;
        };

        Page.prototype.RemoveHelperInformation = function () {
            delete this.ComponentMap;
            delete this.PageNo;
            delete this.PageSelector;
            delete this.Section;
            delete this.Settings;

            this.Content.forEach(component => component.RemoveHelperInformation());
        };

        return Page;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));