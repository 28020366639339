/**
 * @require ./pdf-designer.window.js
 */
(function (global) {
    if (!global.Model) {
        global.Model = {};
    }

    /**
     * @typedef Dimensions
     * @property {int} Width
     * @property {int} Height
     */

    /**
     * @typedef Marginal
     * @property {int} Height
     * @property {BaseComponent[]} Content
     */

    /**
     * @typedef {PageFormat}
     * @type {{A4: string}}
     */
    global.Model.PageFormat = {
        A4: 'a4'
    };

    /**
     * @typedef {PageOrientation}
     * @type {{Landscape: string, Portrait: string}}
     */
    global.Model.PageOrientation = {
        /**
         * Querformat
         */
        Landscape: 'landscape',
        /**
         * Hochformat
         */
        Portrait: 'portrait'
    };

    /**
     * @typedef {SectionSettings}
     */
    global.Model.SectionSettings = (function () {
        /**
         * @param {string} format
         * @param {string} orientation
         * @constructor
         */
        function SectionSettings(format, orientation) {
            /**
             * @type {string}
             */
            this.Format = format;

            /**
             * @type {string}
             */
            this.Orientation = orientation;
        }

        return SectionSettings;
    })();

    /**
     * @typedef {MarginalSettings}
     */
    global.Model.MarginalSettings = (function () {
        /**
         * @param {string} baseSelector
         * @param {string} settingsContainerSelector
         * @param {Function} onPageClick
         * @param {Function} onComponentClick
         * @param {Function} onComponentEdit
         * @param {Function} onComponentRemove
         * @constructor
         */
        function MarginalSettings(baseSelector, settingsContainerSelector, onPageClick, onComponentClick, onComponentEdit, onComponentRemove) {
            /**
             * @type {string}
             * @readonly
             */
            this.BaseSelector = baseSelector + ' ';

            /**
             * @type {string}
             * @readonly
             */
            this.SettingsContainerSelector = this.BaseSelector + settingsContainerSelector;

            /**
             * @type {Function}
             * @readonly
             */
            this.OnPageClick = onPageClick;

            /**
             * @type {Function}
             * @readonly
             */
            this.OnComponentClick = onComponentClick;

            /**
             * @type {Function}
             * @readonly
             */
            this.OnComponentEdit = onComponentEdit;

            /**
             * @type {Function}
             * @readonly
             */
            this.OnComponentRemove = onComponentRemove;
        }

        return MarginalSettings;
    })();

    /**
     * @typedef {Marginal}
     */
    global.Model.Marginal = (function () {
        /**
         * @param {MarginalSettings} settings
         * @param {number} height
         * @param {string} renderArea
         * @param {object[]|null} content
         * @param {ComponentFactory|null} componentFactory
         * @constructor
         */
        function Marginal(settings, height, renderArea, content, componentFactory) {
            this.Settings = settings;
            this.Content = [];
            this.ComponentMap = {};
            this.Height = height;

            if (!(content || []).length) {
                return;
            }

            for (let cCnt = 0; cCnt < (content || []).length; cCnt++){
                let component = componentFactory.CreateComponentFromDefinition(content[cCnt], renderArea);

                if (component == null) {
                    continue;
                }

                this.Content.push(component);
                this.ComponentMap[component.OID] = component;

                component.SetMarginal(this);
            }

            /**
             * @type {string}
             * @readonly
             */
            this.PageSelector = `${this.Settings.BaseSelector} .page`;

            /**
             * @type {boolean}
             * @readonly
             */
            this.IsHeader = renderArea === global.Model.Enums.SectionModificationArea.Header;

            /**
             * @type {boolean}
             * @readonly
             */
            this.IsFooter = renderArea === global.Model.Enums.SectionModificationArea.Footer;
        }

        /**
         * Fügt dem Randbereich eine Komponente hinzu
         * @param {BaseComponent} component
         * @return this
         */
        Marginal.prototype.AddComponent = function (component) {
            if (!component || component.IsTemplate) {
                return;
            }

            this.Content.push(component);
            this.ComponentMap[component.OID] = component;

            component.SetMarginal(this);

            return this;
        };

        /**
         * Gibt eine Komponente anhand ihres Identifiers zurück
         * @param {string} identifier
         * @return {BaseComponent|null}
         */
        Marginal.prototype.GetComponent = function (identifier) {
            if (!identifier) {
                return null;
            }

            return this.ComponentMap[identifier];
        };

        /**
         * Entfernt eine Komponente anhand des Identifiers
         * @param {string} identifier
         * @return this
         */
        Marginal.prototype.RemoveComponent = function (identifier) {
            if (!identifier) {
                return null;
            }

            const component = this.GetComponent(identifier);

            if (!component) {
                return this;
            }

            component.Remove();

            const idx = Tools.indexOf(this.Content, identifier, 'OID');

            if (idx === -1) {
                return this;
            }

            this.Content.splice(idx, 1);
            delete this.ComponentMap[identifier];

            return this;
        };

        /**
         * Setzt die relative Höhe es Randbereichs
         * @param {number} relativeHeight
         * @return {Marginal}
         */
        Marginal.prototype.SetHeight = function (relativeHeight) {
            if (typeof relativeHeight !== 'number' || isNaN(relativeHeight) || relativeHeight < 0) {
                return this;
            }

            this.Height = relativeHeight;

            return this;
        };

        /**
         * Gibt die relative Höhe des Randbereichs zurück
         * @return {Number}
         */
        Marginal.prototype.GetHeight = function () {
            return this.Height;
        };

        /**
         * Versucht Komponenten, die sich außerhalb des Randbereichs befinden, neu zu positionieren.
         * @return {{FailedToPosition: number, OutOfPosition: number}}
         */
        Marginal.prototype.TryRealignComponents = function () {
            const componentsOutOfPosition = this.Content.filter(component => {
                if (this.IsHeader) {
                    return component.Height + component.Position.Coordinates.Y > this.Height;
                }

                return component.Position.Coordinates.Y < 100 - this.Height;
            });

            const result = {
                OutOfPosition: componentsOutOfPosition.length,
                FailedToPosition: 0
            };

            if (componentsOutOfPosition.length === 0) {
                return result;
            }

            for (const component of componentsOutOfPosition) {
                const newYPosition = this.IsHeader ? this.Height - component.Height : 100 - this.Height;

                if (this.IsHeader && newYPosition >= 0 && newYPosition + component.Height <= this.Height) {
                    component.SetCoordinates(component.Position.Coordinates.X, newYPosition);
                } else if (this.IsFooter && newYPosition >= 100 - this.Height && newYPosition + component.Height <= 100) {
                    component.SetCoordinates(component.Position.Coordinates.X, newYPosition);
                } else {
                    result.FailedToPosition++;
                }
            }

            return result;
        };

        /**
         * Entfernt alle Hilfsinformationen
         */
        Marginal.prototype.RemoveHelperInformation = function () {
            delete this.Settings;
            delete this.PageSelector;
            delete this.ComponentMap;
            delete this.IsHeader;
            delete this.IsFooter;

            this.Content.forEach(component => component.RemoveHelperInformation());
        };

        return Marginal;
    })();

    /**
     * @typedef {Section}
     */
    global.Model.Section = (function () {
        /**
         * @param {SectionSettings} sectionSettings
         * @param {PageSettings} pageSettings
         * @param {Marginal|null} header
         * @param {Marginal|null} footer
         * @param {Page[]|object[]|null} pages
         * @param {ComponentFactory} componentFactory
         * @constructor
         */
        function Section(sectionSettings, pageSettings, header, footer, pages, componentFactory) {
            if (sectionSettings == null) {
                throw new Error('No settings for this section provided');
            }

            /**
             * @type {SectionSettings}
             * @readonly
             */
            this.Settings = sectionSettings;

            /**
             * @type {Page[]}
             * @readonly
             */
            this.Segments = [];

            const renderArea = global.Model.Enums.SectionModificationArea.Page;

            for (let pgNo = 0; pgNo < (pages || []).length; pgNo++){
                let page = pages[pgNo];

                if (!(page instanceof global.Model.Page)) {
                    if (!componentFactory) {
                        continue;
                    }

                    const components = [];

                    for (let cCnt = 0; cCnt < (page.Content || []).length; cCnt++){
                        let component = componentFactory.CreateComponentFromDefinition(page.Content[cCnt], renderArea);

                        if (component == null) {
                            continue;
                        }

                        components.push(component);
                    }

                    page = new global.Model.Page(pageSettings, pgNo + 1, components);
                }

                this.Segments.push(page);
            }

            /**
             * @type {Marginal|null}
             */
            this.Header = header;

            /**
             * @type {Marginal|null}
             */
            this.Footer = footer;

            (this.Segments || []).forEach(p => p.SetSection(this));
        }

        /**
         * Rendert den Bereich inklusive aller Komponenten
         * @return {string}
         * @constructor
         */
        Section.prototype.Render = function () {
            return this.Segments
                .map(p => p.Render())
                .join('');
        };

        /**
         * Versucht alle Komponenten innerhalb der Sektion neu zu positionieren, um zu verhindern, dass sie außerhalb ihrer Grenzen liegen
         * @return {{FailedToPosition: number, OutOfPosition: number}}
         */
        Section.prototype.TryRealignComponents = function () {
            const result = {
                OutOfPosition: 0,
                FailedToPosition: 0
            };

            if (this.HasHeader()) {
                const headerResult = this.Header.TryRealignComponents();

                result.OutOfPosition = headerResult.OutOfPosition;
                result.FailedToPosition = headerResult.FailedToPosition;
            }

            if (this.HasFooter()) {
                const footerResult = this.Footer.TryRealignComponents();

                result.OutOfPosition += footerResult.OutOfPosition;
                result.FailedToPosition += footerResult.FailedToPosition;
            }

            if (this.HasContent()) {
                this.Segments.forEach(p => {
                    const pageResult = p.TryRealignComponents();

                    result.OutOfPosition += pageResult.OutOfPosition;
                    result.FailedToPosition += pageResult.FailedToPosition;
                })
            }

            return result;
        };

        /**
         * Fügt dem Bereich eine neue Seite hinzu
         * @param {Page} page
         */
        Section.prototype.AddPage = function (page) {
            if (!page) {
                return;
            }

            page.SetSection(this);
            this.Segments.push(page);

            return this;
        };

        /**
         * Gibt eine Seite des Bereichs anhand der Seitennummer zurück
         * @param {number} idx
         * @return {Page|null}
         */
        Section.prototype.GetPage = function (idx) {
            if (typeof idx !== 'number' || isNaN(idx)) {
                return null;
            }

            return this.Segments[idx];
        };

        /**
         * Entfernt eine Seite aus dem Bereich
         * @param {number} idx
         */
        Section.prototype.RemovePage = function (idx) {
            if (typeof idx !== 'number' || isNaN(idx)) {
                return;
            }

            this.Segments.splice(idx, 1);

            return this;
        };

        /**
         * Gibt zurück, ob der Bereich eine Kopfzeile besitzt
         * @return {boolean}
         */
        Section.prototype.HasHeader = function () {
            return this.Header && (this.Header.Content || []).length > 0;
        };

        /**
         * Gibt zurück, ob der Bereich Komponenten im Inhaltsbereich besitzt
         * @return {boolean}
         */
        Section.prototype.HasContent = function () {
            return (this.Segments || []).some(p => p.HasContent());
        };

        /**
         * Gibt zurück, ob der Bereich eine Fußzeile besitzt
         * @return {boolean}
         */
        Section.prototype.HasFooter = function () {
            return this.Footer && (this.Footer.Content || []).length > 0;
        };

        return Section;
    })();
})(Modifications.Popups.PdfDesigner || (Modifications.Popups.PdfDesigner = {}));